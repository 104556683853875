import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  rootData: any = {};

  private userIDSubject = new BehaviorSubject<string>(null);
  userID$ = this.userIDSubject.asObservable();

  private loginSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public isLoggedIn$ = this.loginSubject.asObservable();

  private updateHistorySubject: BehaviorSubject<boolean> = new BehaviorSubject(
    false
  );
  public updateHistory$ = this.updateHistorySubject.asObservable();

  private appLoadingSubject: BehaviorSubject<boolean> = new BehaviorSubject(
    false
  );
  public appLoading$ = this.appLoadingSubject.asObservable();

  private querySubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public querySub$ = this.querySubject.asObservable();

  private dataSubject = new BehaviorSubject<{
    enableRanking: boolean;
    enableAuthor: boolean;
  }>({ enableRanking: false, enableAuthor: false });
  data$ = this.dataSubject.asObservable();

  constructor(private http: HttpClient, private router: Router) {
    this.rootData.backendUrl = environment.backendUrl;
    this.rootData.tqllcBackend = environment.tqllcBackend;
    this.rootData.tqllcFrontendUrl = environment.tqllcFrontendUrl;
    this.rootData.env = environment.env;
  }

  searchAnalysis(
    query: string,
    token: string,
    userId: string,
    beliefCheckStatus: boolean = false,
    stopWordStatus: boolean = false,
    spellCheckStatus: boolean = false
  ): Observable<any> {
    let payload: any;

    let avoidCheckerList = [5];

    if (beliefCheckStatus) {
      avoidCheckerList.push(1);
    }
    if (stopWordStatus) {
      avoidCheckerList.push(3);
    }
    if (spellCheckStatus) {
      avoidCheckerList.push(0);
    }

    if (this.rootData.env == 'dev-portal') {
      payload = {
        q: query,
        nc:
          localStorage.getItem('isCached') &&
          localStorage.getItem('isCached') === 'true'
            ? false
            : true,
        ac: avoidCheckerList,
      };
    } else {
      payload = {
        q: query,
        nc: false,
        ac: avoidCheckerList,
      };
    }

    return this.http
      .post(
        this.rootData.backendUrl + userId + '/check_errors_search_post',
        payload,
        {
          headers: { 'Content-Type': 'application/json' },
        }
      )
      .pipe(
        tap((response: any) => {
          if (response.query && response.resultCode == 1) {
            let searchResp = {
              title: query,
              response: response,
            };
            sessionStorage.setItem('searchQuery', query);
            sessionStorage.setItem(
              'query_response',
              JSON.stringify(searchResp)
            );
            sessionStorage.removeItem('query_error');
            response.statistics['isFromCache'] =
              response?.isFromCache == true ? true : false;
          } else {
            let searchError = {
              title: query,
              error: response,
            };
            sessionStorage.setItem('searchQuery', query);
            sessionStorage.setItem('query_error', JSON.stringify(searchError));
            // need to remove this to let parts of speech and others contain previous query's data incase of uncivil words check etc.
            // sessionStorage.removeItem('query_response');
          }
          this.querySubject.next(true);
        })
      );
  }

  getUserId(userId: string) {
    this.router.navigate(['']);
    this.userIDSubject.next(userId);
  }

  setStorageValue(type: string, key: string, value: string): any {
    if (!value) {
      return;
    }

    return type == 'local'
      ? localStorage.setItem(key, value)
      : sessionStorage.setItem(key, value);
  }

  removeAllItemsFromStorage(): void {
    return localStorage.clear();
  }

  logout() {
    this.removeAllItemsFromStorage();
    localStorage.removeItem('access_token');
    localStorage.removeItem('userId');
    localStorage.removeItem('userName');
    localStorage.removeItem('email');
    localStorage.removeItem('mobile');
    localStorage.removeItem('profile_pic');
    window.location.href = this.rootData.tqllcFrontendUrl;
  }

  getUserDetails(userId: string): Observable<any> {
    localStorage.removeItem('isfreetrial');

    return this.http
      .post(this.rootData.tqllcBackend + 'token/createToken', {
        userId: userId,
      })
      .pipe(
        tap((response: any) => {
          if (response.meta.code == 200) {
            localStorage.setItem('access_token', response.data.myToken);
            localStorage.setItem('userId', response.data._id);
            localStorage.setItem('userName', response.data.name);
            localStorage.setItem('email', response.data.email);
            localStorage.setItem('mobile', response.data.mobile);
            localStorage.setItem(
              'subscription_details',
              JSON.stringify(response.data.subscription_details)
            );
            localStorage.setItem('isfreetrial', response.data.isFreeTrial);
            localStorage.setItem('profile_pic', response.data.profileImage);
            this.loginSubject.next(true);
          } else {
            this.loginSubject.next(false);
          }
        })
      );
  }

  getUserHistoryCount(userId: string, token: string): Observable<any> {
    return this.http.get(
      this.rootData.tqllcBackend +
        'searchHistory/searchHistoryUserCount?userId=' +
        userId,
      {
        headers: { Authorization: 'Bearer ' + token },
      }
    );
  }

  getSearchSuggestions(token: string, search: string): Observable<any> {
    return this.http.get(
      this.rootData.tqllcBackend +
        'cache/get-search-suggestions?search=' +
        search,
      {
        headers: { Authorization: 'Bearer ' + token },
      }
    );
  }

  setLoaderEvent(event: any) {
    if (event) {
      this.appLoadingSubject.next(true);
    } else {
      this.appLoadingSubject.next(false);
    }
  }

  sendErrorEmailReport(token: string) {
    return this.http
      .post(
        this.rootData.tqllcBackend + 'contact/sendError',
        {},
        {
          headers: { Authorization: 'Bearer ' + token },
        }
      )
      .pipe(
        tap((response: any) => {
          if (response.meta.code == 200) {
            console.log('Error mail Triggered');
          }
        })
      );
  }

  saveSearchHistory(
    query: string,
    userId: string,
    token: string
  ): Observable<any> {
    return this.http
      .post(
        this.rootData.tqllcBackend + 'searchHistory/searchHistorySave',
        { searchKey: query, userId: userId },
        {
          headers: { Authorization: 'Bearer ' + token },
        }
      )
      .pipe(
        tap((response: any) => {
          if (response.meta.code == 200) {
            this.updateHistorySubject.next(true);
          } else {
            this.updateHistorySubject.next(false);
          }
        })
      );
  }

  getHistory(userId: string, token: string): Observable<any> {
    return this.http.get(
      this.rootData.tqllcBackend +
        'searchHistory/searchHistoryListUserWise?userId=' +
        userId,
      {
        headers: { Authorization: 'Bearer ' + token },
      }
    );
  }

  removeMenuHistory(
    userId: string,
    token: string,
    query: string
  ): Observable<any> {
    return this.http
      .post(
        this.rootData.tqllcBackend + 'searchHistory/deleteHistoryUserWise',
        { searchKey: query, userId: userId },
        {
          headers: { Authorization: 'Bearer ' + token },
        }
      )
      .pipe(
        tap((response: any) => {
          if (response.meta.code == 200) {
            this.updateHistorySubject.next(true);
          } else {
            this.updateHistorySubject.next(false);
          }
        })
      );
  }

  getKeywordDef(
    keyword: string,
    userId: string,
    token: string
  ): Observable<any> {
    return this.http.get(
      this.rootData.backendUrl + userId + '/get_definition?q=' + keyword
    );
  }

  saveUserFeedback(
    name: string,
    email: string,
    reqObj: any,
    userId: string,
    token: string
  ): Observable<any> {
    reqObj['userId'] = userId;
    reqObj['createdAt'] = new Date().toISOString();
    reqObj['email'] = email;
    reqObj['name'] = name;

    return this.http.post(
      this.rootData.tqllcBackend + 'contact/feedback',
      reqObj,
      {
        headers: { Authorization: 'Bearer ' + token },
      }
    );
  }

  saveReportedBug(reqObj: any, token: string): Observable<any> {
    return this.http.post(
      this.rootData.tqllcBackend + 'contact/report-bug',
      reqObj,
      {
        headers: { Authorization: 'Bearer ' + token },
      }
    );
  }

  // Cached APIs
  getVersion(): Observable<any> {
    return this.http.get(
      this.rootData.tqllcBackend + 'cache/get-version?env=' + this.rootData.env,
      {}
    );
  }

  getAssertionList(
    offset: number,
    limit: number,
    search: string
  ): Observable<any> {
    return this.http.get(
      this.rootData.tqllcBackend +
        'cache/get-assertions?offset=' +
        offset +
        '&limit=' +
        limit +
        '&search=' +
        search,
      {}
    );
  }

  getGoogleAnswer(id: string): Observable<any> {
    return this.http.get(
      this.rootData.tqllcBackend + 'cache/get-google-answers-id/' + id,
      {}
    );
  }

  deleteGoogleAnswer(id: string): Observable<any> {
    return this.http.delete(
      this.rootData.tqllcBackend + 'cache/delete-answers-by-id/' + id,
      {}
    );
  }

  updateAssertion(id: string, updateData: any): Observable<any> {
    return this.http.put(
      this.rootData.tqllcBackend + 'cache/update-assertions-by-id/' + id,
      updateData,
      {}
    );
  }

  createGoogleAnswer(updateData: any): Observable<any> {
    return this.http.post(
      this.rootData.tqllcBackend + 'cache/create-google-answers-id',
      updateData,
      {}
    );
  }

  updateGoogleAnswer(id: string, updateData: any): Observable<any> {
    return this.http.put(
      this.rootData.tqllcBackend + 'cache/update-google-answers-id/' + id,
      updateData,
      {}
    );
  }

  deleteAssertion(id: string): Observable<any> {
    return this.http.delete(
      this.rootData.tqllcBackend + 'cache/delete-assertions-by-id/' + id,
      {}
    );
  }
  setData(
    data: { enableRanking: boolean; enableAuthor: boolean },
    demoFlag: boolean
  ) {
    if (demoFlag) {
      localStorage.setItem('demo_data', 'true');
    } else {
      localStorage.setItem('demo_data', 'false');
    }
    this.dataSubject.next(data);
  }
}
