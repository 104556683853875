<div class="main_dashboard_section" [ngClass]="{'citation-page' : currentRoute == '/citations'}">
  <div class="search-history-panel" *ngIf="currentRoute !== '/' && currentRoute !== '/cache-system' && currentRoute !== '/unauthorized' && currentRoute !== '/expired' && currentRoute !== '/plan-expired'">
    <app-left-history-panel></app-left-history-panel>
  </div>

  <div class="result-header-section" [ngClass]="{'home-header' : currentRoute == '/'}"
    *ngIf="currentRoute !== '/cache-system' && currentRoute !== '/unauthorized' && currentRoute !== '/expired' && currentRoute !== '/plan-expired'">
    <app-header></app-header>
  </div>

  <div class="right-content-section" [ngClass]="{'main-search-p' : currentRoute == '/', 'unauthorized-page' : currentRoute == '/unauthorized', 'cache-sys-page' : currentRoute == '/cache-system'}">
    <router-outlet></router-outlet>
  </div>
</div>