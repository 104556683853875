export const environment = {
  production: false,
  env: 'dev-portal',
  // frontendUrl: 'https://dev-portal.tqllc.company/',
  frontendUrl: 'https://dev-knowledge-base.authentification.app/',
  backendUrl: 'https://dev-server-knowledge-base.truthquotient.co/',
  // backendUrl: 'http://127.0.0.1:5000/',
  tqllcFrontendUrl: 'https://truthquotient.co/',
  // tqllcBackend: 'https://api.tqllc.company/v1/',
  // tqllcBackend: ‘http://localhost:3005/v1/’,
  tqllcBackend: 'https://dev-knowledge-base-api.truthquotient.co/v1/',
};
