<!-- Mat loader starts -->
<div class="page_loader" *ngIf="isLoading">
  <!-- <mat-spinner></mat-spinner> -->
  <div class="text-loader">
    <img src="../../assets/images/svg/logo-icon.svg" alt="logo icon" />
    <div class="word"></div>
  </div>
</div>
<!-- Mat loader starts -->

<!-- warning toast starts -->
<div class="toast error static-toaster" *ngIf="showErrorToaster">
  <div class="outer-container">
    <img src="../../assets/images/svg/warning-icon.svg" alt="warning toaster" />
  </div>
  <div class="inner-container">
    <h3>Error</h3>
    <p>{{ showErrorMsg }}</p>
  </div>
  <a (click)="closeToaster()" class="pop-close"
    ><img src="../../assets/images/svg/Cancel.svg" alt="close alert"
  /></a>
</div>
<div class="toast success static-toaster" *ngIf="showSuccessToaster">
  <div class="outer-container">
    <img
      src="../../assets/images/svg/success-toaster-icon.svg"
      alt="warning toaster"
    />
  </div>
  <div class="inner-container">
    <h3>Success</h3>
    <p>{{ showSuccessMsg }}</p>
  </div>
  <a (click)="closeToaster()" class="pop-close"
    ><img src="../../assets/images/svg/Cancel.svg" alt="close alert"
  /></a>
</div>
<!-- warning toast ends -->
<div
  class="feedback_popup_section error-word"
  *ngIf="searchTitle != 'keyword_popup'"
>
  <div class="feedback-popup-header clearfix">
    <h3 *ngIf="headerSelect">Assertion spelling check “{{ searchTitle }}”</h3>
    <h3 *ngIf="!headerSelect">Search string “{{ searchTitle }}”</h3>
    <a href="javascript:void(0);" class="pop-close" (click)="onNoClick()"
      ><img
        src="../../assets/images/svg/Cancel.svg"
        alt="close alert"
        style="width: 24px; height: 24px"
    /></a>
  </div>
  <div mat-dialog-content>
    <div class="feedback_popup-desp">
      <ul>
        <li>
          <div class="fb_quest" *ngIf="spellError.resultCode == 1">
            <h3><em class="red-dot"></em> Spell check</h3>
            <!-- <div *ngFor="let corrections of spellError.corrections" class="spell-suggest">
								<p *ngIf="corrections.correct" class="stop_words_para">Correct word(s) to use:
									<b>{{corrections.correct}}</b>
								</p>
								<p *ngIf="corrections.definition ">Definition: <b>{{corrections.definition}}</b></p>
							</div> -->
            <p *ngIf="spellError.suggestions_list.length > 0">
              Suggestions (Please click on a suggestion):
              <span
                *ngFor="
                  let suggestion of spellError.suggestions_list;
                  let last = last
                "
                style="cursor: pointer; color: #027af6"
                (click)="onSuggestionClick(suggestion)"
                >&nbsp;<b>{{ suggestion }}</b
                >{{ !last ? "," : "" }}</span
              >
            </p>
            <!-- <div *ngIf="spellError.suggestions_list.length > 1">⦿ Suggestions (Please click
								on a suggestion):
								<div class="dropdown suggestion_dpdown">
									<span class="dropdown-toggle" id="dropdownMenuButton" data-mdb-toggle="dropdown"
										@@ -101,189 +120,346 @@ <h3><em class="red-dot"></em> Spell check</h3>
									style="cursor:pointer;color: #027af6;"
									(click)="onSuggestionClick(suggestion)">{{spellError.suggestions_list[0]}}</span>
							</div> -->
          </div>
        </li>
        <li>
          <div class="fb_quest" *ngIf="beliefError.resultCode == 1">
            <h3><em class="red-dot"></em> Belief check</h3>
            <h3
              *ngIf="
                beliefError.corrections[0].show_suggestion;
                else noSuggestion
              "
            >
              We've identified belief-oriented language in your assertion. To
              enhance the accuracy of the Authentification results, change your
              assertion to the provided suggestion edition below.
            </h3>
            <ng-template #noSuggestion>
              <h3>
                We’ve identified belief-oriented language in your assertion.
              </h3>
            </ng-template>
            <div *ngFor="let corrections of beliefError.corrections">
              <!-- <p class="stop_words_para"> -->
              <!-- ⦿ Correct this word(s): -->
              <!-- <b>{{ -->
              <!-- string_between_strings( -->
              <!-- corrections.startPos, -->
              <!-- corrections.endPos, -->
              <!-- searchTitle -->
              <!-- ) -->
              <!-- }}</b> -->
              <!-- </p> -->
            </div>
            <p
              *ngIf="
                beliefError.corrections.length > 0 &&
                beliefError.corrections[0].correct
              "
            >
              ⦿ Suggestions:
              <span
                *ngFor="
                  let suggestion of beliefError.corrections;
                  let last = last
                "
                style="cursor: pointer; color: #027af6; display: block"
                (click)="onSuggestionClick(suggestion.correct)"
                >&nbsp;<b>{{ suggestion.correct }}</b
                >{{ !last ? "," : "" }}</span
              >
            </p>
            <p>
              You may proceed with your original input, considering its
              potential impact to the Authentification results.
            </p>
          </div>
        </li>
        <li>
          <div class="fb_quest" *ngIf="uncivilError.resultCode == 1">
            <h3><em class="red-dot"></em> Uncivil Assertion Check</h3>
            <h3>
              Your assertion contains uncivil and/or vulgar language, violating
              our policies. You won’t be able to proceed with your assertion
              query.
            </h3>
            <!-- <div *ngFor="let corrections of uncivilError.corrections"> -->
            <!-- <p class="stop_words_para"> -->
            <!-- ⦿ Correct this word(s): -->
            <!-- <b>{{ -->
            <!-- string_between_strings( -->
            <!-- corrections.startPos, -->
            <!-- corrections.endPos, -->
            <!-- searchTitle -->
            <!-- ) -->
            <!-- }}</b> -->
            <!-- </p> -->
            <!-- </div> -->
            <!-- <p *ngIf="uncivilError.suggestions_list.length > 0"> -->
            <!-- ⦿ Suggestions (Please click on a suggestion): -->
            <!-- <span -->
            <!-- *ngFor=" -->
            <!-- let suggestion of uncivilError.suggestions_list; -->
            <!-- let last = last -->
            <!-- " -->
            <!-- style="cursor: pointer; color: #027af6" -->
            <!-- (click)="onSuggestionClick(suggestion)" -->
            <!-- >&nbsp;<b>{{ suggestion }}</b -->
            <!-- >{{ !last ? "," : "" }}</span -->
            <!-- > -->
            <!-- </p> -->
          </div>
        </li>
        <li>
          <div class="fb_quest" *ngIf="stopWordsError.resultCode == 1">
            <h3><em class="red-dot"></em> Stop Words Assertion Check</h3>
            <h3>
              Avoid absolutes like ‘forever’ or ‘always’ for more accurate
              results. You may proceed if you wish, but bear in mind that
              results may vary due to the use of such terms
            </h3>
            <!-- <div *ngFor="let corrections of stopWordsError.corrections"> -->
            <!-- <p class="stop_words_para"> -->
            <!-- ⦿ Correct this word(s): -->
            <!-- <b>{{ -->
            <!-- string_between_strings( -->
            <!-- corrections.startPos, -->
            <!-- corrections.endPos, -->
            <!-- searchTitle -->
            <!-- ) -->
            <!-- }}</b> -->
            <!-- </p> -->
            <!-- </div> -->
            <!-- <p *ngIf="stopWordsError.suggestions_list.length > 0"> -->
            <!-- ⦿ Suggestions (Please click on a suggestion): -->
            <!-- <span -->
            <!-- *ngFor=" -->
            <!-- let suggestion of stopWordsError.suggestions_list; -->
            <!-- let last = last -->
            <!-- " -->
            <!-- style="cursor: pointer; color: #027af6" -->
            <!-- (click)="onSuggestionClick(suggestion)" -->
            <!-- >&nbsp;<b>{{ suggestion }}</b -->
            <!-- >{{ !last ? "," : "" }}</span -->
            <!-- > -->
            <!-- </p> -->
          </div>
        </li>
        <li>
          <div class="fb_quest" *ngIf="questionError.resultCode == 1">
            <h3><em class="red-dot"></em>Question:</h3>
            <p>⦿ Is this a question? Please write a simplified assertion</p>
            <p *ngIf="questionError.suggestions_list.length > 0">
              ⦿ Suggestions (Please click on a suggestion):
              <span
                *ngFor="
                  let suggestion of questionError.suggestions_list;
                  let last = last
                "
                style="cursor: pointer; color: #027af6"
                (click)="onSuggestionClick(suggestion)"
                >&nbsp;<b>{{ suggestion }}</b
                >{{ !last ? "," : "" }}</span
              >
            </p>
            <!-- <div *ngFor="let corrections of questionError.corrections">
								<p>Correct this word(s): <b>{{corrections}}</b></p>
							</div> -->
          </div>
        </li>
        <li>
          <div class="fb_quest" *ngIf="complexSentenceError.resultCode == 1">
            <h3><em class="red-dot"></em>Complex/compound sentence</h3>
            <p>
              ⦿ Oops, you have entered a complex/compound sentence. Please write
              a simplified assertion
            </p>
            <p *ngFor="let correction of complexSentenceError.corrections">
              ⦿ {{ correction.definition }}:
              <b>{{
                string_between_strings(
                  correction.startPos,
                  correction.endPos,
                  searchTitle
                )
              }}</b>
            </p>
            <p *ngIf="complexSentenceError.suggestions_list.length > 0">
              ⦿ Suggestions (Please click on a suggestion):
              <span
                *ngFor="
                  let suggestion of complexSentenceError.suggestions_list;
                  let last = last
                "
                style="cursor: pointer; color: #027af6"
                (click)="onSuggestionClick(suggestion)"
                >&nbsp;<b>{{ suggestion }}</b
                >{{ !last ? "," : "" }}</span
              >
            </p>
            <!-- <div *ngFor="let corrections of questionError.corrections">
								<p>Correct this word(s): <b>{{corrections}}</b></p>
							</div> -->
          </div>
        </li>
      </ul>
    </div>

    <div
      class="update_assertion"
      *ngIf="headerSelect && uncivilError.resultCode == 1"
      style="
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
      "
    >
      <div class="create_citation">
        <button [disabled]="!searchQuery" (click)="onNoClick()">Okay</button>
      </div>
    </div>
    <div
      class="update_assertion"
      *ngIf="
        headerSelect &&
        (checkIsEmpty(uncivilError) ||
          (uncivilError && uncivilError.resultCode === 0))
      "
    >
      <div class="search_citation">
        <!-- <mat-form-field>
						<input matInput placeholder="Type here..." name="my_citation" value="{{searchQuery}}" id="search1"
							(input)="onChange($event, 'search_query')" autocomplete="off" (keyup.enter)="createAnalysis()">
					</mat-form-field> -->
        <div
          id="search1"
          type="text"
          contenteditable="true"
          (change)="onChange($event, 'search_query')"
          (keyup.enter)="createAnalysis()"
        >
          <div
            *ngFor="let word of searchQuery.split(' '); let i = index"
            style="display: inline-block"
          >
            <div
              *ngIf="bugWords.includes(word)"
              class="dropdown suggestion_dpdown"
              style="display: inline-block"
            >
              <span
                [id]="word"
                class="worng_word"
                [ngClass]="{ 'first-span': i == 0 }"
                id="dropdownMenuButton"
                data-mdb-toggle="dropdown"
                aria-expanded="false"
                >{{ word }}&nbsp;
                <div class="main-suggest-block">
                  <div class="suggestion_drop">
                    <div class="suggest-title">
                      <h3>Correct</h3>
                    </div>
                    <div class="suggest-data">
                      <!-- <p>Possible spelling mistake found.</p> -->
                      <div class="suggestion_ul">
                        <ul>
                          <div *ngFor="let crt of correctins; let j = index">
                            <li
                              [id]="crt.word + j"
                              class="dropdown-item"
                              *ngIf="crt.word == word"
                              (click)="onCorrectionClick(crt)"
                            >
                              {{ crt.correctins }}
                            </li>
                          </div>
                        </ul>
                      </div>
                      <!-- <button class="ignore_btn" (click)="onIgnoreClick(word" )>Ignore</button> -->
                    </div>
                  </div>
                </div>
              </span>
            </div>
            <div *ngIf="!bugWords.includes(word)" style="display: inline-block">
              <span [ngClass]="{ 'first-span': i == 0 }">{{ word }}&nbsp;</span>
            </div>
          </div>
        </div>
      </div>
      <div class="create_citation">
        <button
          [disabled]="!searchQuery"
          (click)="
            createAnalysis(
              true,
              beliefError.resultCode == 1,
              stopWordsError.resultCode == 1,
              spellError.resultCode == 1
            )
          "
        >
          Check Assertion
        </button>
      </div>
    </div>

    <div class="update_assertion" *ngIf="!headerSelect">
      <div>
        <!-- <div class="search_citation"> -->
        <!-- <mat-form-field> -->
        <!-- <input -->
        <!-- matInput -->
        <!-- placeholder="Type here..." -->
        <!-- name="my_citation" -->
        <!-- value="{{ searchQuery }}" -->
        <!-- (input)="onChange($event, 'search_query')" -->
        <!-- autocomplete="off" -->
        <!-- id="search2" -->
        <!-- (keyup.enter)="createAnalysis(true)" -->
        <!-- /> -->
        <!-- </mat-form-field> -->
        <!-- </div> -->
        <div class="create_citation" style="padding: 30px 20px 18px 15px">
          <button
            [disabled]="!searchQuery"
            (click)="
              createAnalysis(
                true,
                beliefError.resultCode == 1,
                stopWordsError.resultCode == 1,
                spellError.resultCode == 1
              )
            "
          >
            Current Assertion
          </button>
        </div>
      </div>
      <div>
        <div class="search_citation">
          <!-- <mat-form-field> -->
          <!-- <input -->
          <!-- matInput -->
          <!-- placeholder="Type here..." -->
          <!-- name="my_citation" -->
          <!-- value="{{ googleSuggestedString }}" -->
          <!-- (input)="onChange($event, 'google_suggested_query')" -->
          <!-- autocomplete="off" -->
          <!-- id="search3" -->
          <!-- (keyup.enter)="createAnalysis(false)" -->
          <!-- /> -->
          <!-- </mat-form-field> -->
        </div>
        <div class="create_citation" style="padding: 30px 20px 18px 15px">
          <button
            [disabled]="!searchQuery"
            (click)="
              createAnalysis(
                false,
                beliefError.resultCode == 1,
                stopWordsError.resultCode == 1,
                spellError.resultCode == 1
              )
            "
          >
            Suggested Assertion
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="feedback_popup_section keyword_list_popup"
  *ngIf="searchTitle == 'keyword_popup'"
>
  <div mat-dialog-content>
    <div class="feedback_popup-desp">
      <p>
        <b>{{ keyword }}</b
        >: {{ keywordDef }}
      </p>
    </div>
  </div>
  <div class="feedback-actions">
    <button class="submit_btn" (click)="onNoClick()">Close</button>
  </div>
</div>
