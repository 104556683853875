import { Component, OnInit, Inject } from '@angular/core';
import {
  MatDialog,
  MAT_DIALOG_DATA,
  MatDialogRef,
} from '@angular/material/dialog';
import { UserService } from '../services/user.service';
import { Router, Event, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-global-confirmation-popup',
  templateUrl: './global-confirmation-popup.component.html',
  styleUrls: ['./global-confirmation-popup.component.scss'],
})
export class GlobalConfirmationPopupComponent implements OnInit {
  // Loader & Error Handling Variables
  isLoading: boolean = false;
  showSuccessToaster: boolean = false;
  showSuccessMsg: string = '';
  showErrorToaster: boolean = false;
  showErrorMsg: string = '';

  searchTitle: string = '';
  spellError: any = {};
  beliefError: any = {};
  uncivilError: any = {};
  stopWordsError: any = {};
  questionError: any = {};
  complexSentenceError: any = {};
  keyword: string = '';
  keywordDef: string = '';

  searchQuery: string = '';
  headerSelect: boolean = true;
  googleSuggestedString: string = '';

  bugWords: any = [];
  correctins: any = [];

  constructor(
    private router: Router,
    public dialogRef: MatDialogRef<GlobalConfirmationPopupComponent>,
    public userService: UserService,
    public dialog: MatDialog
  ) {}

  ngOnDestroy(): void {
    sessionStorage.removeItem('query_error');
  }

  checkIsEmpty(obj: any): boolean {
    return Object.keys(obj).length === 0;
  }
  ngOnInit(): void {
    let storageError = sessionStorage.getItem('query_error')
      ? JSON.parse(sessionStorage.getItem('query_error'))
      : '';

    let googleSuggestion = sessionStorage.getItem('query_response')
      ? JSON.parse(sessionStorage.getItem('query_response'))
      : '';

    if (storageError) {
      this.headerSelect = true;
      let tempSearchQuery = storageError.title.replace(/%20/g, ' ');
      this.searchTitle = tempSearchQuery;
      this.searchQuery = tempSearchQuery;
      this.keyword = storageError.keyword ? storageError.keyword : '';
      this.keywordDef =
        storageError.error && storageError.error[0]
          ? storageError.error[0]
          : '';
      if (storageError.error.errorChecks) {
        for (let err of storageError.error.errorChecks) {
          if (
            err.title == 'Spell check' ||
            err.title == 'Spell check internal'
          ) {
            this.spellError = {};
            this.spellError.resultCode = err.resultCode;
            this.spellError.corrections = err.corrections
              ? err.corrections
              : [];
            this.spellError.suggestions_list = err.suggestions_list
              ? err.suggestions_list
              : [];
          }
          if (err.title == 'belief check') {
            this.beliefError = {};
            this.beliefError.resultCode = err.resultCode;
            this.beliefError.corrections = err.corrections
              ? err.corrections
              : [];
            this.beliefError.suggestions_list = err.suggestions_list
              ? err.suggestions_list
              : [];
          }
          if (err.title == 'uncivil check') {
            this.uncivilError = {};
            this.uncivilError.resultCode = err.resultCode;
            this.uncivilError.corrections = err.corrections
              ? err.corrections
              : [];
            this.uncivilError.suggestions_list = err.suggestions_list
              ? err.suggestions_list
              : [];
          }
          if (err.title == 'Stop words') {
            this.stopWordsError = {};
            this.stopWordsError.resultCode = err.resultCode;
            this.stopWordsError.corrections = err.corrections
              ? err.corrections
              : [];
            this.stopWordsError.suggestions_list = err.suggestions_list
              ? err.suggestions_list
              : [];
          }
          if (err.title == 'Question?') {
            this.questionError = {};
            this.questionError.resultCode = err.resultCode;
            this.questionError.corrections = err.corrections
              ? err.corrections
              : [];
            this.questionError.suggestions_list = err.suggestions_list
              ? err.suggestions_list
              : [];
          }
          if (err.title == 'Compound/Complex sentence') {
            this.complexSentenceError = {};
            this.complexSentenceError.resultCode = err.resultCode;
            this.complexSentenceError.corrections = err.corrections
              ? err.corrections
              : [];
            this.complexSentenceError.suggestions_list = err.suggestions_list
              ? err.suggestions_list
              : [];
          }
        }
      }
      this.showAssertionSpellErrors();
      // need clarity on why it is needed
      // sessionStorage.removeItem('query_error');
    } else if (
      googleSuggestion?.response?.searchBoxAdditionalInfo?.additionalString
    ) {
      this.headerSelect = false;
      let tempSearchQuery = googleSuggestion.title.replace(/%20/g, ' ');
      this.searchTitle = tempSearchQuery;
      this.searchQuery = tempSearchQuery;
      this.googleSuggestedString =
        googleSuggestion?.response?.searchBoxAdditionalInfo?.additionalString;
    } else {
      this.openToaster(false, true, 'Failed to retrieve search string error.');
      sessionStorage.removeItem('query_error');
    }
  }

  string_between_strings(start, end, str): string {
    return str.substring(start, end + 1);
  }

  showAssertionSpellErrors(): any {
    for (let err of this.spellError.corrections) {
      let temp = this.string_between_strings(
        err.startPos,
        err.endPos + 1,
        this.searchQuery
      );
      this.bugWords.push(temp.trim());
      console.log(this.bugWords);

      for (let err1 of err.correctins) {
        this.correctins.push({
          word: temp.trim(),
          correctins: err1,
          startPos: err.startPos,
          endPos: err.endPos,
        });
      }
    }
  }

  onCorrectionClick(correctWord) {
    let element = document.getElementById(correctWord.word);
    element.innerText = correctWord.correctins;
    this.bugWords = this.bugWords.filter((item) => item !== correctWord.word);
    this.correctins = this.correctins.filter(
      (item) => item.word !== correctWord.word
    );
    this.searchQuery = this.searchQuery.replace(
      correctWord.word,
      correctWord.correctins
    );
  }

  // onIgnoreClick(ignoreWord): void {
  // 	console.log(ignoreWord);
  // }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSuggestionClick(suggestion): void {
    this.searchQuery = suggestion;
  }

  onChange(value: any, key: any) {
    if (key == 'search_query') {
      this.searchQuery = value.target.innerText;
    }
    if (key == 'google_suggested_query') {
      this.googleSuggestedString = value.target.value;
    }
  }

  createAnalysis(
    performAction: boolean,
    beliefCheckStatus: boolean,
    stopWordStatus: boolean,
    spellCheckStatus: boolean
  ): void {
    this.onNoClick();
    this.isLoading = true;
    this.searchQuery = document?.getElementById('search1')?.innerText
      ? document?.getElementById('search1')?.innerText
      : this.searchQuery;
    sessionStorage.removeItem('query_response');
    sessionStorage.removeItem('query_error');
    let userId = localStorage.getItem('userId');
    let token = localStorage.getItem('access_token');
    let searchCount = 0;

    this.userService.setLoaderEvent(true);
    this.userService.getUserHistoryCount(userId, token).subscribe(
      (Resp: any) => {
        if (Resp.meta.code == 200) {
          searchCount = Resp.data.count;
          this.performAnalysis(
            token,
            userId,
            performAction,
            beliefCheckStatus,
            stopWordStatus,
            spellCheckStatus
          );
        } else {
          this.performAnalysis(
            token,
            userId,
            performAction,
            beliefCheckStatus,
            stopWordStatus,
            spellCheckStatus
          );
        }
      },
      (err: any) => {
        console.log(err);
      }
    );
  }

  performAnalysis(
    token: string,
    userId: string,
    performAction: boolean,
    beliefCheckStatus: boolean,
    stopWordStatus: boolean,
    spellCheckStatus: boolean
  ) {
    let searchQueryToPass = '';
    if (performAction) {
      searchQueryToPass = this.searchQuery;
    } else {
      searchQueryToPass = this.googleSuggestedString;
    }

    let tempQuery = searchQueryToPass.trim().split(/\s/);
    let tempSearchQuery = tempQuery.join(' ');
    // let tempSearchQuery = encodeURIComponent(searchQueryToPass);
    // tempSearchQuery = tempSearchQuery.replace(/%C2%A0/g, ' ');
    // tempSearchQuery = tempSearchQuery.replace(/%25/g, '%');
    this.userService
      .searchAnalysis(
        tempSearchQuery,
        token,
        userId,
        beliefCheckStatus,
        stopWordStatus,
        spellCheckStatus
      )
      .subscribe(
        (Response: any) => {
          this.userService.setLoaderEvent(false);
          this.isLoading = false;
          if (Response.resultCode == 0) {
            this.dialog.open(GlobalConfirmationPopupComponent, {
              disableClose: true,
            });
            this.ngOnInit();
          } else if (Response.query && Response.resultCode == 1) {
            this.userService
              .saveSearchHistory(tempSearchQuery, userId, token)
              .subscribe(
                (Resp: any) => {
                  this.router.navigate(['/query'], {
                    queryParams: { search: tempSearchQuery },
                  });
                },
                (error: any) => {
                  this.openToaster(
                    false,
                    true,
                    'Failed to retrieve search analysis.'
                  );
                  console.log(error);
                }
              );
            this.onNoClick();
          } else {
            this.userService.sendErrorEmailReport(token);
            this.openToaster(
              false,
              true,
              'Failed to retrieve search results for "' +
                searchQueryToPass +
                '". Server is currently busy, please try again in some time.'
            );
          }
        },
        (error: any) => {
          this.isLoading = false;
          this.userService.sendErrorEmailReport(token);
          this.openToaster(
            false,
            true,
            'Failed to retrieve search results for "' +
              searchQueryToPass +
              '". Server is currently busy, please try again in some time.'
          );
        }
      );
  }

  openToaster(isSuccess: boolean, isError: boolean, message: string) {
    if (isSuccess) {
      this.showSuccessToaster = true;
      this.showSuccessMsg = message;
    } else if (isError) {
      this.showErrorToaster = true;
      this.showErrorMsg = message;
    }

    setTimeout(() => {
      this.closeToaster();
    }, 10000);
  }

  closeToaster() {
    this.showErrorToaster = false;
    this.showErrorMsg = '';
    this.showSuccessToaster = false;
    this.showSuccessMsg = '';
  }
}
