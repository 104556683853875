import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ModalPopupService } from '../services/modalPopup.services';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { UserService } from '../services/user.service';

const dbincr = 0;
@Component({
	selector: 'app-globalmodalpopup',
	templateUrl: './globalmodalpopup.component.html',
	styleUrls: ['./globalmodalpopup.component.scss']
})
export class GlobalmodalpopupComponent implements OnInit {
	// Loader & Error Handling Variables
	isLoading: boolean = false;
	showSuccessToaster: boolean = false;
	showSuccessMsg: string = "";
	showErrorToaster: boolean = false;
	showErrorMsg: string = "";
	zoomLevel: number = 1;

	dialog: any;
	searchResponse: any = {};
	answerBox: string = '';
	sentVisualizationHTML: SafeHtml = '';
	statKeywords: any = [];
	statKeywordsArray: any = [];
	debunkprogress: any = 0;
	pvyProgressValue: any = 0;
	entities: any = [];
	searchStatistics: any = {};
	feedbackConclusion: string;
	reportConclusion: string;
	feedbackRating: string = "";
	selectedRating: number = 0;
	totalStars: number[] = [1, 2, 3, 4, 5];
	maxRating: number = 5;
	feedbackMeterScore: string = "";
	feedbackOften: string = "";
	feedbackPrice: string = "";

	showFeedbackPopup = false;
	showWelcomePopup = false;
	showPartsPopup = false;
	showGeneralPopup = false;
	showGeneralAnalysisPopup = false;
	showEntitiesPopup = false;
	showCharacterCounterPopup = false;
	showAnswerBoxPopup = false;
	showReportBoxPopup = false;

	constructor(public dialogRef: MatDialogRef<GlobalmodalpopupComponent>, private modalPopupService: ModalPopupService, private sanitizer: DomSanitizer, public userService: UserService) {
		if (sessionStorage.getItem('query_response')) {
			this.searchResponse = JSON.parse(sessionStorage.getItem('query_response'))?.response || {};
		}
	}

	ngOnInit(): void {
		if (sessionStorage.getItem('feedback') == 'true') {
			this.showFeedbackPopup = true;
		}
		if (sessionStorage.getItem('welcome') == 'true') {
			this.showWelcomePopup = true;
		}
		if (sessionStorage.getItem('parts_of_speech') == 'true') {
			this.showPartsPopup = true;
			$('.global-modal-popup').addClass('parts-of-speech');
		}
		if (sessionStorage.getItem('general_definition') == 'true') {
			this.showGeneralPopup = true;
		}
		if (sessionStorage.getItem('general_analysis') == 'true') {
			this.showGeneralAnalysisPopup = true;
		}
		if (sessionStorage.getItem('entities') == 'true') {
			this.showEntitiesPopup = true;
		}
		if (sessionStorage.getItem('character_counter') == 'true') {
			this.showCharacterCounterPopup = true;
			$('.global-modal-popup').addClass('character');
		}
		if (sessionStorage.getItem('answer_box') == 'true') {
			this.showAnswerBoxPopup = true;
		}
		if (sessionStorage.getItem('report_bug') == 'true') {
			this.showReportBoxPopup = true;
		}

		this.setResponseData();
	}

	closeModal() {
		this.modalPopupService.removePopup();
		// document.getElementsByClassName("animate__animated")[0].classList.remove("animate__bounceIn");
		// document.getElementsByClassName("animate__animated")[0].classList.add("animate__backOutLeft");

		setTimeout(() => {
			this.dialogRef.close();
		}, 200);
	}

	setResponseData() {
		this.answerBox = (this.searchResponse?.searchBoxResults || 'No answer found for this assertion').replace(/\n/g, '<br/>');
		this.sentVisualizationHTML = this.sanitizer.bypassSecurityTrustHtml(this.searchResponse?.sent_visualization || '');
		this.statKeywords = new Set(this.searchResponse?.statistics?.keywords_List);
		this.pvyProgressValue = this.searchResponse?.statistics?.probabilityOfValidity;
		const pvyProgressVal = 100 - this.searchResponse?.statistics?.probabilityOfValidity;
		this.debunkprogress = pvyProgressVal.toFixed(1).replace(/\.0$/, '');
		this.entities = this.searchResponse?.entities;
		this.searchStatistics = this.searchResponse?.statistics || {};

		this.getKeywordDef();
	}

	applySVGStyles() {
		const svgElements = document.querySelectorAll('.displacy');
		svgElements.forEach((svgElement: HTMLElement) => {
			svgElement.style.maxWidth = '100%';
			svgElement.style.height = 'auto';
		});
	}

	getKeywordDef(): void {
		let userId = localStorage.getItem('userId');
		let token = localStorage.getItem('access_token');
		this.statKeywordsArray = [];

		for (let keyword of this.statKeywords) {
			this.userService.getKeywordDef(keyword, userId, token).subscribe((Response: any) => {
				if (Response.defs && Response.defs.length > 0) {
					let finalDef = (Response.defs && Response.defs[0])
						? Response.defs[0].split(';').reduce((acc, str) => {
							str = str.trim();
							acc.push(str.charAt(0).toUpperCase() + str.slice(1));
							return acc;
						}, []) : [];

					let temp = {
						keyword: keyword,
						definition: finalDef.slice(0, 3)
					}
					this.statKeywordsArray.push(temp);
				}
			});
		}
	}

	onChange(temp: any, val: any, key: any) {
		if (key === 'report_conclusion') {
			this.reportConclusion = temp.target.value;
		}
		else if (key === 'feedback_conclusion') {
			this.feedbackConclusion = temp.target.value;
		} else if (key === 'feedback_rating') {
			this.feedbackRating = this.getFeedbackLabel(val);
			this.selectedRating = this.getRatingValue(val);
		} else if (key === 'feedback_meter') {
			this.feedbackMeterScore = val;
		} else if (key === 'feedback_frequency') {
			this.feedbackOften = val;
		} else if (key === 'feedback_price') {
			this.feedbackPrice = val;
		} else {
			console.log('Invalid key');
		}
	}

	getFeedbackLabel(key: string): string {
		switch (key) {
			case 'very_satisfied':
				return 'Very Satisfied';
			case 'satisfied':
				return 'Satisfied';
			case 'neutral':
				return 'Neutral';
			case 'unsatisfied':
				return 'Unsatisfied';
			case 'very_unsatisfied':
				return 'Very Unsatisfied';
			default:
				return '';
		}
	}

	getRatingValue(key: string): number {
		switch (key) {
			case 'very_unsatisfied':
				return 1;
			case 'unsatisfied':
				return 2;
			case 'neutral':
				return 3;
			case 'satisfied':
				return 4;
			case 'very_satisfied':
				return 5;
			default:
				return 0;
		}
	}

	saveFeedback(): void {
		let userId = localStorage.getItem('userId');
		let userName = localStorage.getItem('userName');
		let email = localStorage.getItem('email');
		let token = localStorage.getItem('access_token');

		let reqObj = {
			"conclusion": this.feedbackConclusion,
			"rating": this.feedbackRating,
			"meter": this.feedbackMeterScore,
			"frequency": this.feedbackOften,
			"features_price": this.feedbackPrice
		}
		this.userService.saveUserFeedback(userName, email, reqObj, userId, token).subscribe((Response: any) => {
			if (Response.meta.code == 200) {
				this.openToaster(true, false, "Feedback form submitted successfully.");
				setTimeout(() => {
					this.closeModal();
				}, 3000);
			} else if (Response.meta.code == 400) {
				this.openToaster(false, true, "Please fill all the fields to submit the feedback form.");
			} else {
				this.openToaster(false, true, "Failed to submit feedback form.");
			}
		}, (error: any) => {
			if (error.status == 400) {
				this.openToaster(false, true, "Please fill all the fields to submit the feedback form.");
			} else {
				this.openToaster(false, true, "Failed to submit feedback form.");
			}
			console.log(error);
		});
	}

	openToaster(isSuccess: boolean, isError: boolean, message: string) {
		if (isSuccess) {
			this.showSuccessToaster = true;
			this.showSuccessMsg = message;
		} else if (isError) {
			this.showErrorToaster = true;
			this.showErrorMsg = message;
		}

		setTimeout(() => {
			// this.closeToaster();
		}, 10000);
	}

	zoomIn() {
		this.zoomLevel += 0.1; // Increase the zoom level
	}

	zoomOut() {
		if (this.zoomLevel > 0.2) { // Set a minimum zoom level
			this.zoomLevel -= 0.1; // Decrease the zoom level
		}
	}

	closeToaster() {
		this.showErrorToaster = false;
		this.showErrorMsg = "";
		this.showSuccessToaster = false;
		this.showSuccessMsg = "";
	}

	savereportbug() {
		let userId = localStorage.getItem('userId');
		let token = localStorage.getItem('access_token');
		let reqObj = {
			"userId": userId,
			"conclusion": this.reportConclusion,
			"createdAt": new Date().toISOString().split('T')[0]
		}

		this.userService.saveReportedBug(reqObj, token).subscribe((Response: any) => {
			if (Response.meta.code == 200) {
				this.openToaster(true, false, "Reported bug successfully.");
				setTimeout(() => {
					this.closeModal();
				}, 3000);
			} else {
				this.openToaster(false, true, "Failed to report bug");
			}
		}, (error: any) => {
			this.openToaster(false, true, "Failed to report bug");
			console.log(error);
		});
	}
}
