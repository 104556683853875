import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { MaterialModule } from './material-module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; 
import { ClickOutsideDirective } from './directives/ClickOutsideDirective';
import { GaugeChartModule } from 'angular-gauge-chart';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';

import { SharedService } from './shared.service';
import { ModalPopupService } from './services/modalPopup.services';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './layout/header/header.component';
import { FooterComponent } from './layout/footer/footer.component';
import { GlobalSearchComponent } from './global-search/global-search.component';
import { SearchResultsComponent } from './search-results/search-results.component';
import { LeftHistoryPanelComponent } from './left-history-panel/left-history-panel.component';
import { MainEngineComponent } from './main-engine/main-engine.component';
import { TruthMeterChartComponent } from './truth-meter-chart/truth-meter-chart.component';
import { GlobalmodalpopupComponent } from './globalmodalpopup/globalmodalpopup.component';
import { UserCheckComponent } from './usercheck/usercheck.component';
import { CitationsComponent } from './citations/citations.component';
import { GlobalConfirmationPopupComponent } from './global-confirmation-popup/global-confirmation-popup.component';
import { CacheComponent } from './cache/cache.component';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { FreeTrialComponent } from './free_trial/free_trial.component';
import { SubExpiredComponent } from './sub_expired/sub_expired.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    GlobalSearchComponent,
    SearchResultsComponent,
    LeftHistoryPanelComponent,
    ClickOutsideDirective,
    MainEngineComponent,
    GlobalmodalpopupComponent,
    UserCheckComponent,
    TruthMeterChartComponent,
    CitationsComponent,
    GlobalConfirmationPopupComponent,
    CacheComponent,
    UnauthorizedComponent,
    FreeTrialComponent,
    SubExpiredComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MaterialModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    GaugeChartModule
  ],
  providers: [SharedService, ModalPopupService],
  bootstrap: [AppComponent]
})
export class AppModule { }
