<!-- Mat loader starts -->
<div class="page_loader" *ngIf="isLoading">
    <!-- <mat-spinner></mat-spinner> -->
    <div class="text-loader">
        <img src="../../assets/images/svg/logo-icon.svg" alt="logo icon" />
        <div class="word"></div>
    </div>
</div>
<!-- Mat loader starts -->

<!-- warning toast starts -->
<div class="toast error static-toaster" *ngIf="showErrorToaster">
    <div class="outer-container">
        <img src="../../assets/images/svg/warning-icon.svg" alt="warning toaster">
    </div>
    <div class="inner-container">
        <h3>Error</h3>
        <p>{{showErrorMsg}}</p>
    </div>
    <a (click)="closeToaster()"><img src="../../assets/images/svg/Cancel.svg" alt="close alert" /></a>
</div>
<div class="toast success static-toaster" *ngIf="showSuccessToaster">
    <div class="outer-container">
        <img src="../../assets/images/svg/success-toaster-icon.svg" alt="warning toaster">
    </div>
    <div class="inner-container">
        <h3>Success</h3>
        <p>{{showSuccessMsg}}</p>
    </div>
    <a (click)="closeToaster()"><img src="../../assets/images/svg/Cancel.svg" alt="close alert" /></a>
</div>
<!-- warning toast ends -->

<!-- <div class="header_block">
    <app-header></app-header>
</div> -->

<div class="global-search-section" *ngIf="!isLoading">
    <form>
        <div class="tqllc-logo">
            <img src="../../assets/images/authentification-logo.png" alt="tqllc logo" />
        </div>
        <div class="search-query">
            <!-- <div class="search-icon">
        <img src="../../assets/images/svg/search-icon.svg" alt="search icon" />
    </div> -->
            <div class="tqls-search">
                <div class="search-input">
                    <!-- <mat-form-field class="custom-mat-form-field-header"> -->
                    <textarea [rows]="rows" #searchText class="main-page-input gb-search-ipt" value="{{searchQuery}}"
                        (input)="onChange($event, 'search_query')" autocomplete="off" [placeholder]="placeholderText"
                        spellcheck="true" name="searchQuery" (clickOutside)="onOutsideClick()" [formControl]="email"
                        (keydown)="preventEnterKey($event)"></textarea>
                    <!-- <textarea class="main-page-input gb-search-ipt" type="text" matInput value="{{searchQuery}}"
                (input)="onChange($event, 'search_query')" autocomplete="off" [placeholder]="placeholderText"
                (keyup.enter)="createAnalysis()" spellcheck="true" name="searchQuery" 
                id="custom-textarea"  (clickOutside)="onOutsideClick()"></textarea> -->
                    <!-- <input class="main-page-input gb-search-ipt" type="text" matInput value="{{searchQuery}}"
            (input)="onChange($event, 'search_query')" autocomplete="off" [placeholder]="placeholderText"
            (keyup.enter)="createAnalysis()" spellcheck="true" name="searchQuery"
            (clickOutside)="onOutsideClick()"> -->
                    <!-- </mat-form-field> -->

                    <div class="delete-query" *ngIf="searchQuery">
                        <a (click)="removeSearch()">
                            <img src="../../assets/images/svg/close-icon.svg" alt="close search" />
                        </a>
                    </div>
                </div>
                <div class="auto-suggitions" *ngIf="searchSuggestions.length > 0">
                    <div class="auto-suggest-scroll">
                        <ul>
                            <li *ngFor="let suggestion of searchSuggestions">
                                <div class="suggest-item clearfix">
                                    <!-- <div class="suggested-score g-border">90</div> -->
                                    <div class="suggested-text" (click)="setSearchQuery(suggestion.assertion_text)">
                                        <span class="highlighted">{{suggestion.assertion_text}}</span>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="search-v-c">
                <ul>
                    <li>
                        <a (click)="createAnalysis()">
                            <img src="../../assets/images/svg/search-icon.svg" alt="search icon" />
                        </a>
                    </li>
                    <!-- <li><a >
                    <img src="../../assets/images/svg/voice-icon.svg" alt="voice search" />
                </a></li>
            <li><a >
                    <img src="../../assets/images/svg/copy-icon.svg" alt="copy search" />
                </a></li> -->
                </ul>
            </div>
        </div>
        <div class="query-guidence">
            <p *ngIf="!searchSuggestions.length > 0">Try pausing for a moment when writing your query to show assertion
                suggestions</p>
        </div>
        <button mat-stroked-button class="go-search" [disabled]="!searchQuery" (click)="createAnalysis()">Analyze
            Assertion</button>
    </form>

    <!-- searched history -->
    <div class="suggitions-history" *ngIf="userHistory.length > 0">
        <h3>History</h3>
        <div class="auto-suggest-scroll">
            <ul>
                <li *ngFor="let hist of userHistory">
                    <div class="suggest-item clearfix">
                        <!-- <div class="suggested-score g-border">90</div> -->
                        <div class="suggested-text" (click)="openHistory(hist)"><span
                                class="highlighted">{{hist}}</span></div>
                    </div>
                </li>
                <!-- <li>
                    <div class="suggest-item clearfix">
                        <div class="suggested-score r-border">90</div>
                        <div class="suggested-text">Is it true that [abc event] happened <span class="highlighted">on
                                earth?</span></div>
                    </div>
                </li>
                <li>
                    <div class="suggest-item clearfix">
                        <div class="suggested-score y-border">90</div>
                        <div class="suggested-text">Elon Musk is planning to leave <span
                                class="highlighted">earth</span> in Dec 2024</div>
                    </div>
                </li>
                <li>
                    <div class="suggest-item clearfix">
                        <div class="suggested-score">90</div>
                        <div class="suggested-text">Elon Musk is planning to leave <span
                                class="highlighted">earth</span> in Dec 2024</div>
                    </div>
                </li>
                <li>
                    <div class="suggest-item clearfix">
                        <div class="suggested-score">90</div>
                        <div class="suggested-text">Elon Musk is planning to leave <span
                                class="highlighted">earth</span> in Dec 2024</div>
                    </div>
                </li> -->
            </ul>
        </div>
    </div>
    <div class="suggitions-history" *ngIf="userHistory.length == 0">
    </div>


    <!-- desktop suggitions -->
    <div class="desktop-suggitions">
        <ul>
            <li *ngFor="let hist of desktopUserHistory"><a (click)="openHistory(hist)" title="{{hist}}">{{hist |
                    slice:0:65}}{{(hist +'').length > 65 ? '...' : ''}}</a></li>
        </ul>
    </div>
</div>



<!-- <div class="footer-logo">
    <img src="../../assets/images/tqllc-footer-logo.png" alt="footer tqllc logo" />
</div> -->