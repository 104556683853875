import { Component, OnInit } from '@angular/core';
import { SharedService } from './../shared.service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { UserService } from '../services/user.service';

@Component({
	selector: 'app-left-history-panel',
	templateUrl: './left-history-panel.component.html',
	styleUrls: ['./left-history-panel.component.scss']
})
export class LeftHistoryPanelComponent implements OnInit {
	// Loader & Error Handling Variables
	isLoading: boolean = false;
	showSuccessToaster: boolean = false;
	showSuccessMsg: string = "";
	showErrorToaster: boolean = false;
	showErrorMsg: string = "";

	clickEventSubscription: Subscription;
	showHistoryMenu: boolean = false;
	showLeftPanel: boolean = false;

	rootData: any = {};
	menuList: any = [];
	userId: string;
	userData: any = {};
	freeTrial: boolean = true;
	freeTrialDay: number = 0;
	selected: any = {};

	constructor(private sharedservice: SharedService, public userService: UserService, private router: Router) {
		this.clickEventSubscription = this.sharedservice.getClickEvent().subscribe(() => {
			this.showLeftSection();
		});
	}

	ngOnInit(): void {
		if (localStorage.getItem('userId') && localStorage.getItem('access_token')) {
			this.getStorage();
			this.getUserDetails();
			if (localStorage.getItem('email') == 'authentificationtrial@tqllc.company') {
				this.showHistoryMenu = true;
			}
		}

		this.userService.isLoggedIn$.subscribe(isloggedIn => {
			if (isloggedIn) {
				this.getStorage();
				this.getUserHistory();
				this.applyFreeTrial();
			}
		});

		this.userService.updateHistory$.subscribe(updatedHistory => {
			if (updatedHistory) {
				this.getUserHistory();
			}
		});
	}

	getStorage() {
		this.userId = localStorage.getItem('userId');
		this.userData.name = localStorage.getItem('userName');
		this.userData.email = localStorage.getItem('email');
		this.userData.profileImage = localStorage.getItem('profile_pic');
		if (this.userData.profileImage && !this.userData.profileImage.includes('googleusercontent.com')) {
			this.userData.profileImage = 'https://api.tqllc.company/' + this.userData.profileImage;
		}
		this.userData.access_token = localStorage.getItem('access_token');
	}

	getUserDetails(): void {
		this.userService.getUserDetails(this.userId).subscribe((Response: any) => {
			if (Response.meta.code == 200) {
				this.getUserHistory();
			}
			else {
				this.openToaster(false, true, 'Unauthorized Access.');
				this.router.navigate(['/unauthorized']);
			}
		}, (error: any) => {
			if (error?.error?.error?.code == 1021) {
				this.openToaster(false, true, 'Your Free Trial is completed. Please purchase a plan to continue.');
				this.router.navigate(['/expired']);
			} else if (error?.error?.error?.code == 1022) {
				this.openToaster(false, true, 'Your Subscription is completed. Please purchase a plan to continue.');
				this.router.navigate(['/plan-expired']);
			} else {
				this.openToaster(false, true, 'Unauthorized Access.');
				this.router.navigate(['/unauthorized']);
			}
		});
	}

	getUserHistory(): void {
		this.userService.getHistory(this.userId, this.userData.access_token).subscribe((Response: any) => {
			if (Response.meta.code == 200) {
				let searchKeys = new Set(Response.data.map((h: any) => (h.searchKey)));
				// this.freeTrialDayCount = 3 - (Array.from(searchKeys).length == 0 ? 0 : Array.from(searchKeys).length);
				this.menuList = Array.from(searchKeys).map((h: any) => {
					return { label: h, route: h };
				});

				for (let list in this.menuList) {
					this.menuList[list].showActions = false;
					this.menuList[list].hideDelete = false;
				}

			} else if (Response.meta.code == 1006) {
				console.log('No History Found');
			} else {
				this.openToaster(false, true, 'Failed to retrieve user history data.');
			}
		}, (error: any) => {
			this.openToaster(false, true, 'Failed to retrieve user history data.');
			console.log(error);
		});
	}

	applyFreeTrial() {
		this.freeTrial = (localStorage.getItem('isfreetrial') == 'true') ? true : false;

		if (this.freeTrial) {
			this.calculateFreeDaysLeft();
		}
	}

	calculateFreeDaysLeft() {
		let subscription_details = JSON.parse(localStorage.getItem('subscription_details'));
		const date1 = new Date(subscription_details.createdAt);
		const date2 = new Date();
		const timeDifferenceMs = date2.getTime() - date1.getTime();
		const daysDifference = Math.floor(timeDifferenceMs / (1000 * 60 * 60 * 24)) + 1;
		if (daysDifference <= 15) {
			this.freeTrialDay = 15 - daysDifference;
		}
	}

	showHistory() {
		this.showHistoryMenu = !this.showHistoryMenu;
	}

	deleteHistoryList(index: number,event:MouseEvent) {
		if(event){
		event.stopPropagation();
		}
		this.menuList[index].showActions = !this.menuList[index].showActions;
		this.menuList[index].hideDelete = !this.menuList[index].hideDelete;
	}

	showLeftSection() {
		this.showLeftPanel = !this.showLeftPanel
	}

	hideLefthistory() {
		this.showLeftPanel = false;
		console.log('hide btn clicked')
	}

	select(type, item, $event, mode) {
		this.selected[type] = this.selected[type] === item ? null : item;
		$event ? $event.stopPropagation() : null;
	}

	isActive(type, item) {
		return this.selected[type] === item;
	}

	removeMenuList(menuItem: string, index: number,event:MouseEvent) {
		let returnQuery = (index != 0) ? this.menuList[0].label : (this.menuList[1].label) ? this.menuList[1].label : '';
		let userId = localStorage.getItem('userId');
		let token = localStorage.getItem('access_token');
		this.userService.removeMenuHistory(userId, token, menuItem).subscribe((Response: any) => {
			if (Response.meta.code == 200) {
				this.openToaster(true, false, 'Deleted Selected History Successfully.');
				setTimeout(() => {
					if (returnQuery)
						this.router.navigate(['/query'], { queryParams: { search: returnQuery } });
					else
						this.router.navigate(['/']);
				}, 1000);
			} else {
				// this.router.navigate(['/unauthorized']);
				this.openToaster(false, true, 'Failed to Delete History Menu.');
			}
		}, (error: any) => {
			// this.router.navigate(['/unauthorized']);
			this.openToaster(false, true, 'Failed to Delete History Menu.');
			console.log(error);
		});
		event.stopPropagation();
	}

	openToaster(isSuccess: boolean, isError: boolean, message: string) {
		if (isSuccess) {
			this.showSuccessToaster = true;
			this.showSuccessMsg = message;
		} else if (isError) {
			this.showErrorToaster = true;
			this.showErrorMsg = message;
		}

		setTimeout(() => {
			this.closeToaster();
		}, 10000);
	}

	closeToaster() {
		this.showErrorToaster = false;
		this.showErrorMsg = "";
		this.showSuccessToaster = false;
		this.showSuccessMsg = "";
	}
}
