import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { GlobalSearchComponent } from './global-search/global-search.component';
import { SearchResultsComponent } from './search-results/search-results.component';
import { UserCheckComponent } from './usercheck/usercheck.component';
import { CitationsComponent } from './citations/citations.component';
import { CacheComponent } from './cache/cache.component';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { FreeTrialComponent } from './free_trial/free_trial.component';
import { SubExpiredComponent } from './sub_expired/sub_expired.component';

const routes: Routes = [
  { path: '', pathMatch: 'full', component: GlobalSearchComponent },
  { path: 'redirect', pathMatch: 'full', component: UserCheckComponent },
  { path: 'query', component: SearchResultsComponent },
  { path: 'citations', component: CitationsComponent },
  { path: 'cache-system', component: CacheComponent },
  { path: 'expired', component: FreeTrialComponent },
  { path: 'plan-expired', component: SubExpiredComponent },
  { path: 'unauthorized', component: UnauthorizedComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
