import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router, Event, NavigationEnd } from '@angular/router';
import { UserService } from '../services/user.service';
import { MatDialog } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { GlobalConfirmationPopupComponent } from '../global-confirmation-popup/global-confirmation-popup.component';

// declare var searchLoader: any;
declare var $: any;

@Component({
	selector: 'app-global-search',
	templateUrl: './global-search.component.html',
	styleUrls: ['./global-search.component.scss'],
	styles: [`
		textarea {
		resize: none;
		overflow: hidden;
		}
	`]
})
export class GlobalSearchComponent implements OnInit {
	@ViewChild('searchText') searchText: ElementRef;
	myForm: FormGroup;
	rows: number = 1;
	email: AbstractControl;

	// Loader & Error Handling Variables
	isLoading: boolean = false;
	showSuccessToaster: boolean = false;
	showSuccessMsg: string = "";
	showErrorToaster: boolean = false;
	showErrorMsg: string = "";

	// App Dataflow Variables
	searchQuery = '';
	userId: string;
	userName: string;
	userHistory: any = [];
	desktopUserHistory: any = [];
	searchSuggestions: any = [];
	private timeout: any;
	containerHeight: string = "auto";
	placeholderText: string = "An assertion is a statement claiming factual validity or invalidity...";

	constructor(private router: Router, public userService: UserService, public dialog: MatDialog, private fb: FormBuilder) {
		this.userService.userID$.subscribe((userId: string) => {
			if (userId) {
				this.userId = userId;
				this.getUserDetails(this.userId);
			} else if (localStorage.getItem('userId') && localStorage.getItem('userId') != null) {
				this.userId = localStorage.getItem('userId');
				this.getUserDetails(this.userId);
			} else {
				this.openToaster(false, true, 'Unauthorized Access');
				this.router.navigate(['/unauthorized']);
			}
		});

		this.userService.appLoading$.subscribe(appLoad => {
			if (appLoad) {
				this.isLoading = true;
			} else {
				this.isLoading = false;
			}
		});

		// Form Builder
		this.myForm = fb.group({
			email: [
				'',
				Validators.compose([Validators.required, Validators.minLength(4)]),
			],
		});
		this.email = this.myForm.controls['email'];
		this.myForm.valueChanges.subscribe((value) => {
			this.searchText.nativeElement.style.height = 'auto';
			this.searchText.nativeElement.style.height = `${this.searchText.nativeElement.scrollHeight}px`;
		});
	}

	ngOnInit(): void {
		var words = ['Please wait for results…', 'Please wait for results…'],
			part,
			i = 0,
			offset = 0,
			len = words.length,
			forwards = true,
			skip_count = 0,
			skip_delay = 15,
			speed = 70;

		var wordflick = function () {
			setInterval(function () {
				if (forwards) {
					if (offset >= words[i].length) {
						++skip_count;
						if (skip_count == skip_delay) {
							forwards = false;
							skip_count = 0;
						}
					}
				}
				else {
					if (offset == 0) {
						forwards = true;
						i++;
						offset = 0;
						if (i >= len) {
							i = 0;
						}
					}
				}
				part = words[i].substr(0, offset);
				if (skip_count == 0) {
					if (forwards) {
						offset++;
					}
					else {
						offset--;
					}
				}
				$('.word').text(part);
			}, speed);
		};

		$(document).ready(function () {
			wordflick();
		});
	}

	removeSearch() {
		this.searchQuery = '';
		const inputField = document.querySelector('input[name="searchQuery"]') as HTMLInputElement;
		if (inputField) {
			inputField.focus();
		}
		this.searchText.nativeElement.style.height = 'auto';
	}

	onChange(value: any, key: any) {
		if (key == 'search_query') {
			let val = (value.target.value) ? value.target.value.trim() : '';
			if (val.length > 0) {
				this.searchQuery = val.charAt(0).toUpperCase() + val.slice(1);
			} else {
				this.searchQuery = '';
			}
			clearTimeout(this.timeout);

			this.timeout = setTimeout(() => {
				this.getSearchSuggestions();
			}, 600);
		}
	}

	preventEnterKey(event: KeyboardEvent): void {
		if (event.key === 'Enter') {
			event.preventDefault();
			this.createAnalysis();
		}
	}

	createAnalysis(): void {
		this.isLoading = true;
		this.searchSuggestions = [];
		let userId = localStorage.getItem('userId');
		let token = localStorage.getItem('access_token');
		// let searchCount = 0;

		this.userService.getUserHistoryCount(userId, token).subscribe((Resp: any) => {
			if (Resp.meta.code == 200) {
				// searchCount = Resp.data.count;
				this.performAnalysis(token, userId);
			} else {
				this.performAnalysis(token, userId);
			}
		}, (err: any) => {
			console.log(err);
		});
	}

	performAnalysis(token, userId) {
		sessionStorage.setItem('searchQuery', this.searchQuery);
		if (this.getTheWordCount(this.searchQuery) > 2) {
			this.searchQuery = this.searchQuery.trim();
			this.userService.searchAnalysis(this.searchQuery, token, userId).subscribe((Response: any) => {
				if (Response.resultCode == 0) {
					setTimeout(() => {
						this.isLoading = false;
						this.dialog.open(GlobalConfirmationPopupComponent, { disableClose: true });
					}, 200);
				} else if (Response.query && Response.resultCode == 1) {
					if (Response?.searchBoxAdditionalInfo?.additionalString && Response?.searchBoxAdditionalInfo?.additionalString != null) {
						setTimeout(() => {
							this.isLoading = false;
							this.dialog.open(GlobalConfirmationPopupComponent, { disableClose: true });
						}, 200);
					} else {
						this.saveHistoryNavigateToQuery(userId, token);
					}
				} else {
					this.userService.sendErrorEmailReport(token);
					this.openToaster(false, true, 'Failed to retrieve search results for "' + this.searchQuery + '". Server is currently busy, please try again in some time.');
				}
			}, (error: any) => {
				this.isLoading = false;
				this.userService.sendErrorEmailReport(token);
				this.openToaster(false, true, 'Failed to retrieve search results for "' + this.searchQuery + '". Server is currently busy, please try again in some time.');
			});
		} else {
			this.isLoading = false;
			this.openToaster(false, true, 'Please enter atleast 3 or more words to test the assertion.');
		}
	}

	getTheWordCount(inputText: string) {
		inputText = inputText.trim();

		// Split the input text into an array of words
		var words = inputText.split(/\s+/);

		// Return the number of words
		return words.length;
	}

	saveHistoryNavigateToQuery(userId: string, token: string): void {
		this.userService.saveSearchHistory(this.searchQuery, userId, token).subscribe((Resp: any) => {
			this.router.navigate(['/query'], { queryParams: { search: this.searchQuery } });
			this.isLoading = false;
		}, (error: any) => {
			this.openToaster(false, true, "Failed to retrieve search analysis.");
			console.log(error);
		});
	}

	getSearchSuggestions() {
		let token = localStorage.getItem("access_token");
		this.userService.getSearchSuggestions(token, this.searchQuery).subscribe((Response: any) => {
			if (Response.meta.code == 200) {
				this.searchSuggestions = Response.data?.assertions;
			} else {
				// this.openToaster(false, true, "Failed to retrieve search suggestions.");
			}
		}, (error: any) => {
			// this.openToaster(false, true, "Failed to retrieve search suggestions.");
			console.log(error);
		});
	}

	getUserHistory(): void {
		let userId = localStorage.getItem("userId");
		let access_token = localStorage.getItem("access_token");
		this.userService.getHistory(userId, access_token).subscribe((Response: any) => {
			if (Response.meta.code == 200) {
				this.userHistory = Array.from(new Set(Response.data.map((h: any) => (h.searchKey))));
				this.desktopUserHistory = this.userHistory.slice(0, 3);
			} else if (Response.meta.code == 1006) {
				console.log('No History Found');
				this.userHistory = [];
			} else {
				this.userHistory = [];
				// this.openToaster(false, true, 'Failed to retrieve user history data.');
			}
		}, (error: any) => {
			this.openToaster(false, true, 'Failed to retrieve user history data.');
			console.log(error);
		});
	}

	setSearchQuery(query: any) {
		this.searchQuery = query;
		this.searchSuggestions = [];
		this.createAnalysis();
	}

	onOutsideClick() {
		this.searchSuggestions = [];
	}

	getUserDetails(id: string): void {
		this.userService.getUserDetails(id).subscribe((Response: any) => {
			this.isLoading = false;
			if (Response.meta.code == 200) {
				this.userName = localStorage.getItem("userName") ? localStorage.getItem("userName").split(" ")[0] : "";
				if (localStorage.getItem('userId') && localStorage.getItem('access_token')) {
					this.getUserHistory();
				}
			}
			else {
				this.openToaster(false, true, 'Unauthorized Access.');
				this.router.navigate(['/unauthorized']);
			}
		}, (error: any) => {
			sessionStorage.removeItem('error-report');
			if (error?.error?.meta?.code == 1021) {
				sessionStorage.setItem('error-report', JSON.stringify({
					heading: "Free Trial Expired",
					message: "Your Free Trial is completed. Please purchase a plan to continue."
				}));
				this.openToaster(false, true, 'Your Free Trial is completed. Please purchase a plan to continue.');
				this.router.navigate(['/unauthorized']);
			} else if (error?.error?.meta?.code == 1022) {
				sessionStorage.setItem('error-report', JSON.stringify({
					heading: "Subscription Expired",
					message: "Your Subscription is completed. Please purchase a plan to continue."
				}));
				this.openToaster(false, true, 'Your Subscription is completed. Please purchase a plan to continue.');
				this.router.navigate(['/unauthorized']);
			} else {
				sessionStorage.setItem('error-report', JSON.stringify({
					heading: "Unauthorized Access",
					message: "This URL is valid but you are not authorized for this content."
				}));
				this.openToaster(false, true, 'Unauthorized Access.');
				this.router.navigate(['/unauthorized']);
			}
			console.log(error);
		});
	}

	openHistory(query: any) {
		this.searchQuery = query;
		this.createAnalysis();
	}

	openToaster(isSuccess: boolean, isError: boolean, message: string) {
		if (isSuccess) {
			this.showSuccessToaster = true;
			this.showSuccessMsg = message;
		} else if (isError) {
			this.showErrorToaster = true;
			this.showErrorMsg = message;
		}

		setTimeout(() => {
			this.closeToaster();
		}, 10000);
	}

	closeToaster() {
		this.showErrorToaster = false;
		this.showErrorMsg = "";
		this.showSuccessToaster = false;
		this.showSuccessMsg = "";
	}
}
