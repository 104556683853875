import { Component, OnInit, Input } from '@angular/core';
import { environment } from '../../environments/environment';

//probability of validity graph
const stbar1 = 0;
const stbar2 = 0;

//change of opinion graph
const truprogress = 36;
const ptvprogress = 86;
const flsprogress = 26;

declare var $: any;

@Component({
	selector: 'app-truth-meter-chart',
	templateUrl: './truth-meter-chart.component.html',
	styleUrls: ['./truth-meter-chart.component.scss']
})
export class TruthMeterChartComponent implements OnInit {

	@Input() truthMeter = 0;
	@Input() labelVal = 0;
	@Input() ranking:boolean;

	// Guage chart
	public canvasWidth;
	public needleValue;
	public counter;
	public centralLabel;
	public label;
	public options;

	// statics 
	pvgprogress = 0;
	pvyprogress: any = 0;

	//chance of opinion
	trueprogress = 0;
	ptprogress = 0;
	falseprogress = 0;

	graphType: string = "TRUE";
	labelText: string = "";
	env: string = environment.env;

	constructor() { }

	ngOnInit(): void {
		this.oribabilityYellowGraph();
		this.probabilityGreenGraph();
		this.trueOpinonGraph();
		this.ptOpinonGraph();
		this.falseOpinonGraph();

		// Guage Chart
		this.canvasWidth = 230;
		this.needleValue = '0';
		this.counter = 0;
		this.centralLabel = '';
		this.options = {
			hasNeedle: true,
			needleColor: '#3B3743',
			needleUpdateSpeed: 3000,
			arcColors: ["#FF1919", "#FF231B", "#FF2C1C", "#FF361E", "#FF3F1F", "#FE4921", "#FE5322", "#FE5C24", "#FE6625", "#FE6F27", "#FE7928", "#FE822A", "#FE8C2B", "#FD962D", "#FD9F2E", "#FDA930", "#FDB231", "#FDBC33", "#FDC634", "#FDCF36", "#FDD937", "#FCE239", "#FCEC3A", "#FCF53C", "#FCFF3D", "#FCFF3D", "#F3FE3C", "#E9FD3C", "#E0FC3B", "#D7FB3A", "#CEFA39", "#C4F939", "#BBF838", "#B2F737", "#A8F636", "#9FF536", "#96F435", "#8DF334", "#83F233", "#7AF133", "#71F032", "#67EF31", "#5EEE30", "#55ED30", "#4BEC2F", "#42EB2E", "#39EA2D", "#30E92D", "#26E82C", "#1DE72B"],
			arcDelimiters: [2, 4, 6, 8, 10, 12, 14, 16, 18, 20, 22, 24, 26, 28, 30, 32, 34, 36, 38, 40, 42, 44, 46, 48, 50, 52, 54, 56, 58, 60, 62, 64, 66, 68, 70, 72, 74, 76, 78, 80, 82, 84, 86, 88, 90, 92, 94, 96, 98],
			arcOverEffect: true,
			// rangeLabel: ['0', '100'],
			needleStartValue: 0,
			arcpadding: 1,
		};

		this.setGuageChart();
		this.setTruthMeterLabel();
	}

	ngOnChanges(changes: any): void {
		if(changes?.truthMeter?.currentValue !== changes?.truthMeter?.previousValue && changes?.truthMeter?.previousValue !== undefined) {
			this.setGuageChart();
		}
	}

	ngAfterViewInit() {
		// Below code implements bob effect.
		setTimeout(() => {
			let step = 1;
			this.options.needleUpdateSpeed = 800;
			const outerInterval = setInterval(() => {
				if (step === 1) {
					this.needleValue = (parseInt(this.needleValue, 10) + 6).toString();
				} else if (step === 2) {
					this.needleValue = (parseInt(this.needleValue, 10) - 6).toString();
				} else if (step === 3) {
					this.needleValue = (parseInt(this.needleValue, 10) - 5).toString();
				} else if (step === 4) {
					this.needleValue = (parseInt(this.needleValue, 10) + 7).toString();
				} else if (step === 5) {
					this.needleValue = (parseInt(this.needleValue, 10) - 4).toString();
				} else if (step === 6) {
					this.needleValue = (parseInt(this.needleValue, 10) + 2).toString();
				}
				step++;
				if (step > 6) {
					step = 1;
				}
			}, 800);
		}, 10000);
	}

	setGuageChart() {
		this.pvgprogress = this.truthMeter;
		const pvyProgressValue = 100 - Number(this.truthMeter);
		this.pvyprogress = pvyProgressValue.toFixed(1).replace(/\.0$/, '');

		this.needleValue = `${this.truthMeter}`;
		if (this.truthMeter >= 0 && this.truthMeter <= 20) {
			this.graphType = "Very Unlikely";
		} else if (this.truthMeter >= 21 && this.truthMeter <= 40) {
			this.graphType = "Unlikely";
		} else if (this.truthMeter >= 41 && this.truthMeter <= 60) {
			this.graphType = "Partially True";
		} else if (this.truthMeter >= 61 && this.truthMeter <= 80) {
			this.graphType = "Likely";
		} else {
			this.graphType = "Very Likely";
		}
	}

	probabilityGreenGraph() {
		if (this.pvgprogress === 100) {
			this.pvgprogress = 0;
		} else {
			this.pvgprogress = this.pvgprogress + stbar1;
		}
	}

	oribabilityYellowGraph() {
		if (this.pvyprogress === 100) {
			this.pvyprogress = 0;
		} else {
			this.pvyprogress = this.pvyprogress + stbar2;
		}
	}
	// Probability validity progress bar 2

	// chance of opinion true progress bar
	trueOpinonGraph() {
		if (this.trueprogress === 100) {
			this.trueprogress = 0;
		} else {
			this.trueprogress = this.trueprogress + truprogress;
		}
	}
	// chance of opinion true progress bar

	// chance of opinion partially progress bar 
	ptOpinonGraph() {
		if (this.ptprogress === 100) {
			this.ptprogress = 0;
		} else {
			this.ptprogress = this.ptprogress + ptvprogress;
		}
	}
	// chance of opinion partially progress bar

	// chance of opinion false progress bar 
	falseOpinonGraph() {
		if (this.falseprogress === 100) {
			this.falseprogress = 0;
		} else {
			this.falseprogress = this.falseprogress + flsprogress;
		}
	}

	setTruthMeterLabel() {
		switch (this.labelVal) {
			case 0: 
				this.labelText = "Google Answer";
				break;
			case 1:
				this.labelText = "Rank System 1";
				break;
			case 2:
				this.labelText = "News System Counter";
				break;
			case 3:
				this.labelText = "Human";
				break;
			case 4:
				this.labelText = "Google Answer With ChatGPT";
				break;
			case 5:
				this.labelText = "ChatGPT Answer & Result";
				break;
			default:
				this.labelText = "Unknown";
		}
	}
}
