import { Component, OnInit } from '@angular/core';
import { UserService } from '../services/user.service';
import { ActivatedRoute } from '@angular/router';
import { Router, Event, NavigationEnd } from '@angular/router';
import { PageEvent } from '@angular/material/paginator';
import { SharedService } from '../shared.service';

@Component({
	selector: 'app-citations',
	templateUrl: './citations.component.html',
	styleUrls: ['./citations.component.scss']
})

export class CitationsComponent implements OnInit {
	// Loader & Error Handling Variables
	isLoading: boolean = false;
	showSuccessToaster: boolean = false;
	showSuccessMsg: string = "";
	showErrorToaster: boolean = false;
	showErrorMsg: string = "";

	currentRoute: string;
	citationsData: any = [];
	totalSources: number = 0;
	pageEvent: PageEvent;
	datasource: null;
	pageIndex: number = 0;
	pageSize: number = 10;
	length: number = 200;
	pageSizeOptions: any = [5, 10, 20, 100];
	selectedCheckBoxes: any = new Set([]);

	constructor(private router: Router, private route: ActivatedRoute, public userService: UserService, private sharedservice: SharedService,) {
		this.currentRoute = "";
		this.router.events.subscribe((event: Event) => {
			if (event instanceof NavigationEnd) {
				this.currentRoute = event.url;
			}
			if (event instanceof NavigationEnd) {
				if (event.url && event.url.split('?')[0])
					this.currentRoute = event.url.split('?')[0];
				else
					this.currentRoute = event.url;
			}
		});
	}

	ngOnInit(): void {
		if (sessionStorage.getItem('create_citation')) {
			this.getCitationsData();
		} else {
			this.isLoading = false;
			let userId = localStorage.getItem('userId');
			this.openToaster(false, true, 'Unrestricted access');
			setTimeout(() => {
				this.router.navigate(['/'], { queryParams: { id: userId } });
			}, 2000);
		}
	}

	getCitationsData(): void {
		this.isLoading = true;
		let citationRequest = JSON.parse(sessionStorage.getItem('create_citation'));

		const outputArray: { id: string, citation: string }[] = citationRequest.citation_id.map((entry: any) => {
			const id = entry.id;
			const title = entry.title;
			const site = entry.site !== null ? entry.site : "None";
			const url = entry.url;

			let citation = "";

			if (citationRequest.citation_format === "apa") {
				const italicizedTitle = `<em>${title}</em>`;
				citation = `${italicizedTitle}. ${site}. ${url}`;
			} else if (citationRequest.citation_format === "mla") {
				// Add MLA formatting logic here
				const quotedTitle = `"${title}."`;
				const italicizedsite = `<em>${site}</em>`;
				citation = `${quotedTitle} ${italicizedsite}, ${url}`;

			} else {
				// Add default formatting logic here
				const quotedTitle = `"${title},"`;
				citation = `${quotedTitle} ${site}, ${url}`;
			}

			return { id, citation };
		});

		console.log("Citations array here: ", outputArray);
		this.citationsData = outputArray;
		this.length = this.citationsData.length;
		this.totalSources = this.citationsData.length;
		this.isLoading = false;
	}

	getRandom = () => { return Math.ceil(Math.random() * 10000) };

	onCheckboxChange(event: any, id: any) {
		if (event.checked) {
			this.selectedCheckBoxes.add(id);
		} else {
			this.selectedCheckBoxes.delete(id);
		}
	}

	public handlePage(e: any): any {
		this.pageIndex = e.pageIndex;
		this.pageSize = e.pageSize;
		this.getCitationsData();
	}

	redirectBack(): void {
		if (sessionStorage.getItem('searchQuery')) {
			this.router.navigate(['/query'], { queryParams: { search: sessionStorage.getItem('searchQuery') } });
		} else {
			this.router.navigate(['/']);
		}
	}

	arrHeader: any = [
		{
			"Citations": "Citations"
		}
	];


	public getSelectedCitation = () => {
		let anyOneSelected = Array.from(this.selectedCheckBoxes).length == 0 ? false : true;
		let allowedCitations: any = [];
		if (anyOneSelected) {
			const temp = Array.from(this.selectedCheckBoxes);
			allowedCitations = this.citationsData.filter(c => temp.includes(c.id))
		}
		else {
			allowedCitations = this.citationsData;
		}
		console.log("Citations copied: ", allowedCitations)
		return allowedCitations.map(e => {
			return {
				'Citations': e.citation ? e.citation.replace(/<em>/g, ' ').replace(/<\/em>/g, ' ') : ''
			}
		});
	}

	copyCitations() {
		let userData = this.getSelectedCitation();
		let temp = userData.map(e => Object.values(e).filter(e => e != "").join('. ')).join(',\n');
		navigator.clipboard.writeText(temp);
		this.openToaster(true, false, 'Citations data is copied to your clipboard');
	}

	downloadCSVFile = () => {
		let userData = this.getSelectedCitation();
		let csvData = this.ConvertToCSV(userData, this.arrHeader);
		let blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
		let dwldLink = document.createElement("a");
		let url = URL.createObjectURL(blob);
		let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
		if (isSafariBrowser) {  //if Safari open in new window to save file with random filename.
			dwldLink.setAttribute("target", "_blank");
		}
		let filename = "Analysis_" + new Date().toISOString();
		dwldLink.setAttribute("href", url);
		dwldLink.setAttribute("download", filename + ".csv");
		dwldLink.style.visibility = "hidden";
		document.body.appendChild(dwldLink);
		dwldLink.click();
		document.body.removeChild(dwldLink);
	}

	ConvertToCSV = (objArray, headerList) => {
		let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
		let str = '';
		let row = '';

		let newHeaders = Object.values(this.arrHeader[0]);

		for (let index in newHeaders) {
			row += newHeaders[index] + ',';
		}
		row = row.slice(0, -1);
		str += row + '\r\n';
		for (let i = 0; i < array.length; i++) {
			let lineContent = [];
			// lineContent.push((i + 1)+"");
			// let line = (i + 1) + '';
			for (let index of Object.keys(headerList[0])) {
				let temp = this.strRep(array[i][index]);
				lineContent.push(temp);
			}
			let line = lineContent.join(',')
			str += line + '\r\n';
		}
		return str;
	}

	strRep = (data) => {
		if (typeof data == "string") {
			let newData = data.replace(/,/g, " ");
			return newData;
		}
		else if (typeof data == "undefined") {
			return "-";
		}
		else if (typeof data == "number") {
			return data.toString();
		}
		else {
			return data;
		}
	}

	openInNewWindow(id: string, url: string): void {
		// Construct the URL using citation.id and citation.url
		const fullUrl = `${id}`;

		// Open the URL in a new browser window
		window.open(fullUrl, '_blank');
	}

	leftMenuAction() {
		this.sharedservice.sendClickEvent();
	}

	openToaster(isSuccess: boolean, isError: boolean, message: string) {
		if (isSuccess) {
			this.showSuccessToaster = true;
			this.showSuccessMsg = message;
		} else if (isError) {
			this.showErrorToaster = true;
			this.showErrorMsg = message;
		}

		setTimeout(() => {
			this.closeToaster();
		}, 10000);
	}

	closeToaster() {
		this.showErrorToaster = false;
		this.showErrorMsg = "";
		this.showSuccessToaster = false;
		this.showSuccessMsg = "";
	}
}
