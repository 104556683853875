import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Injectable()
export class ModalPopupService {
	constructor(private dialog: MatDialog) { }

	openPopup<T>(component: any, data: any): MatDialogRef<any> {
		return this.dialog.open(component, {
			width: '550px',
			data: data,
			disableClose: true
		});
	}

	closePopup(dialogRef: MatDialogRef<any>) {
		dialogRef.close('closed forcefully');
	}

	setPopup(data: any) {
		this.removePopup();
		
		if (data == "feedback") {
			sessionStorage.setItem('feedback', 'true');
		}
		if (data == "welcome") {
			sessionStorage.setItem('welcome', 'true');
		}
		if (data == "parts_of_speech") {
			sessionStorage.setItem('parts_of_speech', 'true');
		}
		if (data == "general_definition") {
			sessionStorage.setItem('general_definition', 'true');
		}
		if (data == "general_analysis") {
			sessionStorage.setItem('general_analysis', 'true');
		}
		if (data == "entities") {
			sessionStorage.setItem('entities', 'true');
		}
		if (data == "character_counter") {
			sessionStorage.setItem('character_counter', 'true');
		}
		if (data == "answer_box") {
			sessionStorage.setItem('answer_box', 'true');
		}
		if (data == "report_bug") {
			sessionStorage.setItem('report_bug', 'true');
		}
	}

	removePopup() {
		sessionStorage.removeItem('feedback');
		sessionStorage.removeItem('welcome');
		sessionStorage.removeItem('parts_of_speech');
		sessionStorage.removeItem('general_definition');
		sessionStorage.removeItem('general_analysis');
		sessionStorage.removeItem('entities');
		sessionStorage.removeItem('character_counter');
		sessionStorage.removeItem('answer_box');
		sessionStorage.removeItem('report_bug');
	}
}