import { Component, OnInit, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { Router, Event, NavigationEnd, ActivatedRoute } from '@angular/router';
import { SharedService } from './../../shared.service';
import { UserService } from '../../services/user.service';
import { GlobalmodalpopupComponent } from '../../globalmodalpopup/globalmodalpopup.component';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ModalPopupService } from '../../services/modalPopup.services';
import { GlobalConfirmationPopupComponent } from '../../global-confirmation-popup/global-confirmation-popup.component';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { environment } from '../../../environments/environment';
import { event } from 'jquery';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    styles: [`
        textarea {
        resize: none;
        overflow: hidden;
        }
	`]
})
export class HeaderComponent implements OnInit {
    @ViewChild('desktopSearch') desktopSearch: ElementRef;
    myForm: FormGroup;
    rows: number = 1;
    email: AbstractControl;

    @ViewChild('mobileSearch') mobileSearch: ElementRef;
    mForm: FormGroup;
    mrows: number = 1;
    msearch: AbstractControl;

    // Loader & Error Handling Variables
    showSuccessToaster: boolean = false;
    showSuccessMsg: string = "";
    showErrorToaster: boolean = false;
    showErrorMsg: string = "";

    rootData: any = {};
    menuList: any;
    selected: any = {};
    isMenuOpen: boolean = false;
    userId: string;
    userData: any = {};
    freeTrial: boolean = true;
    freeTrialDay: number = 0;
    showHistoryMenu: boolean = false;
    resizeSearch: boolean = false;
    resizeMSearch: boolean = false;

    searchQuery = '';
    currentRoute: string;
    userSettings: boolean = false;
    userdeskSettings: boolean = false;
    mobileMenu: boolean = false;
    dialogRef: MatDialogRef<any>;
    isCachedStored: string = "false";
    searchSuggestions: any = [];
    showSuggestions = true;
    isDemoCheck: string = "false";
    env: string = environment.env;
    private timeout: any;
    placeholderText: string = "An assertion is a statement claiming factual validity or invalidity...";

    constructor(private router: Router, private route: ActivatedRoute, private sharedservice: SharedService, public userService: UserService, private modalPopupService: ModalPopupService, private dialog: MatDialog, private gs: FormBuilder, private ms: FormBuilder, private renderer: Renderer2) {
        this.currentRoute = "";
        this.router.events.subscribe((event: Event) => {
            if (event instanceof NavigationEnd) {
                this.currentRoute = event.url;
            }
            if (event instanceof NavigationEnd) {
                if (event.url && event.url.split('?')[0])
                    this.currentRoute = event.url.split('?')[0];
                else
                    this.currentRoute = event.url;
            }

            if (this.currentRoute == '/query') {
                this.route.queryParams
                    .subscribe(params => {
                        this.searchQuery = params.search || '';
                        this.email.setValue(this.searchQuery);
                        this.msearch.setValue(this.searchQuery);
                    });
            } else {
                this.searchQuery = '';
                this.email.setValue(this.searchQuery);
                this.msearch.setValue(this.searchQuery);
            }
        });

        let demoData = localStorage.getItem('demo_data');
        if (demoData && demoData == 'true') {
            this.isDemoCheck = 'true';
            this.userService.setData({ enableRanking: true, enableAuthor: true }, true);
        } else {
            this.isDemoCheck = 'false';
            this.userService.setData({ enableRanking: false, enableAuthor: false }, false);
        }

        // Desktop Search Builder
        this.myForm = gs.group({
            email: [
                '',
                Validators.compose([Validators.required, Validators.minLength(4)]),
            ],
        });
        this.email = this.myForm.controls['email'];
        // this.myForm.valueChanges.subscribe((value) => {
        //     this.desktopSearch.nativeElement.style.height = 'auto';
        //     this.desktopSearch.nativeElement.style.height = `${this.desktopSearch.nativeElement.scrollHeight}px`;
        // });

        // Mobile Search Builder
        this.mForm = ms.group({
            msearch: [
                '',
                Validators.compose([Validators.required, Validators.minLength(4)]),
            ],
        });
        this.msearch = this.mForm.controls['msearch'];
        // this.mForm.valueChanges.subscribe((value) => {
        //     this.mobileSearch.nativeElement.style.height = 'auto';
        //     this.mobileSearch.nativeElement.style.height = `${this.mobileSearch.nativeElement.scrollHeight}px`;
        // });
        this.email.valueChanges.subscribe(value => {
            if (value.length > 0) {
                this.searchQuery = value.charAt(0).toUpperCase() + value.slice(1);
            } else {
                this.searchQuery = '';
            }
        });

        this.msearch.valueChanges.subscribe(value => {
            if (value.length > 0) {
                this.searchQuery = value.charAt(0).toUpperCase() + value.slice(1);
            } else {
                this.searchQuery = '';
            }
        });
    }

    ngOnInit(): void {
        if (localStorage.getItem('userId') && localStorage.getItem('access_token')) {
            this.getStorage();
            this.getUserDetails();
            if (localStorage.getItem('email') == 'authentificationtrial@tqllc.company') {
                this.showHistoryMenu = true;
            }
        }

        this.userService.isLoggedIn$.subscribe(isloggedIn => {
            if (isloggedIn) {
                this.getStorage();
                // this.getUserHistory();
                // this.applyFreeTrial();
            }
        });

        this.isCachedStored = localStorage.getItem('isCached') || 'false';
    }

    ngAfterViewInit() {
        this.myForm.valueChanges.subscribe((value) => {
            if (this.desktopSearch) {
                this.desktopSearch.nativeElement.style.height = 'auto';
                this.desktopSearch.nativeElement.style.height = `${this.desktopSearch.nativeElement.scrollHeight}px`;
            }
        });
        this.mForm.valueChanges.subscribe((value) => {
            if (this.mobileSearch) {
                this.mobileSearch.nativeElement.style.height = 'auto';
                this.mobileSearch.nativeElement.style.height = `${this.mobileSearch.nativeElement.scrollHeight}px`;
            }
        });
    }

    onChange(value: any, key: any) {
        if (key == 'search_query') {
            let val = (value.target.value) ? value.target.value.trim() : '';
            if (val.length > 0) {
                this.searchQuery = val.charAt(0).toUpperCase() + val.slice(1);
            } else {
                this.searchQuery = '';
            }

            clearTimeout(this.timeout);

            this.timeout = setTimeout(() => {
                this.getSearchSuggestions();
            }, 600);
        }
        const enteredText = value.target.value
        // Split the entered text into lines
        const lines = enteredText.split('\n');
        // Reverse the order of lines
        lines.reverse();
        // Update the searchQuery with the modified text
        this.searchQuery = lines.join('\n');
        // Adjust the height of the textarea based on content
        // this.adjustTextareaHeight();
    }

    // adjustTextareaHeight() {
    // Get the textarea element
    //     const textarea = document.querySelector('.custom-textarea') as HTMLTextAreaElement;
    //     textarea.style.height = 'auto';
    //     textarea.style.height = (textarea.scrollHeight) + 'px';
    // }

    setSearchQuery(query: any) {
        this.searchQuery = query;
        this.searchSuggestions = [];
        this.createAnalysis();
    }

    preventEnterKey(event: KeyboardEvent): void {
        if (event.key === 'Enter') {
            event.preventDefault();
            this.createAnalysis();
        }
    }

    createAnalysis() {
        this.showSuggestions = false;
        this.searchSuggestions = [];
        this.userService.setLoaderEvent(true);
        let userId = localStorage.getItem('userId');
        let token = localStorage.getItem('access_token');
        let searchCount = 0;

        this.userService.getUserHistoryCount(userId, token).subscribe((Resp: any) => {
            if (Resp.meta.code == 200) {
                searchCount = Resp.data.count;
                this.performAnalysis(token, userId);
            } else {
                this.performAnalysis(token, userId);
            }
        }, (err: any) => {
            console.log(err);
        });
    }

    performAnalysis(token, userId) {
        sessionStorage.setItem('searchQuery', this.searchQuery);
        if (this.getTheWordCount(this.searchQuery) > 2) {
            this.searchQuery = this.searchQuery.trim();
            this.userService.searchAnalysis(this.searchQuery, token, userId).subscribe((Response: any) => {
                if (Response.resultCode == 0) {
                    setTimeout(() => {
                        this.userService.setLoaderEvent(false);
                        this.dialog.open(GlobalConfirmationPopupComponent, { disableClose: true });
                    }, 200);
                } else if (Response.query && Response.resultCode == 1) {
                    if (Response?.searchBoxAdditionalInfo?.additionalString && Response?.searchBoxAdditionalInfo?.additionalString != null) {
                        setTimeout(() => {
                            this.userService.setLoaderEvent(false);
                            this.dialog.open(GlobalConfirmationPopupComponent, { disableClose: true });
                        }, 200);
                    } else {
                        this.saveHistoryNavigateToQuery(userId, token);
                    }
                } else {
                    this.userService.sendErrorEmailReport(token);
                    this.openToaster(false, true, 'Failed to retrieve search results for "' + this.searchQuery + '". Server is currently busy, please try again in some time.');
                }
            }, (error: any) => {
                this.userService.setLoaderEvent(false);
                this.userService.sendErrorEmailReport(token);
                this.openToaster(false, true, 'Failed to retrieve search results for "' + this.searchQuery + '". Server is currently busy, please try again in some time.');
            });
        } else {
            this.userService.setLoaderEvent(false);
            this.openToaster(false, true, 'Please enter atleast 3 or more words to test the assertion.');
        }
    }

    getTheWordCount(inputText: string) {
        inputText = inputText.trim();

        // Split the input text into an array of words
        var words = inputText.split(/\s+/);

        // Return the number of words
        return words.length;
    }

    saveHistoryNavigateToQuery(userId: string, token: string): void {
        this.userService.saveSearchHistory(this.searchQuery, userId, token).subscribe((Resp: any) => {
            this.router.navigate(['/query'], { queryParams: { search: this.searchQuery } });
            this.userService.setLoaderEvent(false);
        }, (error: any) => {
            // this.openToaster(true, true, "Error", "Failed to retrieve search analysis.");
            console.log(error);
        });
    }

    getSearchSuggestions() {
        let token = localStorage.getItem("access_token");
        this.userService.getSearchSuggestions(token, this.searchQuery).subscribe((Response: any) => {
            if (Response.meta.code == 200) {
                this.searchSuggestions = Response.data?.assertions;
            } else {
                // this.openToaster(false, true, "Failed to retrieve search suggestions.");
            }
        }, (error: any) => {
            // this.openToaster(false, true, "Failed to retrieve search suggestions.");
            console.log(error);
        });
    }

    removeSearch() {
        this.searchQuery = '';
        this.searchSuggestions = [];
        const inputField = document.querySelector('input[name="searchQuery"]') as HTMLInputElement;
        if (inputField) {
            inputField.focus();
        }
        // this.desktopSearch.nativeElement.style.height = 'auto';
        this.mobileSearch.nativeElement.style.height = 'auto';
    }

    onOutsideClick() {
        this.searchSuggestions = [];
    }

    userProfile() {
        this.userSettings = !this.userSettings;
        this.userdeskSettings = !this.userdeskSettings;
    }

    showMobileMenu() {
        this.mobileMenu = !this.mobileMenu;
    }

    closeMmenu() {
        this.mobileMenu = false;
    }

    closeSettings() {
        this.userSettings = false;
    }

    closedeskSettings() {
        this.userdeskSettings = false;
    }

    leftMenuAction() {
        this.sharedservice.sendClickEvent();
    }

    closeHeaderSearch() {
        this.desktopSearch.nativeElement.style.height = 'auto';
        this.resizeSearch = false;
    }

    closeMobileSearch() {
        this.mobileSearch.nativeElement.style.height = 'auto';
        this.resizeMSearch = false;
    }

    resizerMSearch() {
        this.mobileSearch.nativeElement.style.height = `${this.mobileSearch.nativeElement.scrollHeight}px`;
        this.resizeMSearch = !this.resizeMSearch;
    }

    resizerSearchHeight() {
        this.desktopSearch.nativeElement.style.height = `${this.desktopSearch.nativeElement.scrollHeight}px`;
        this.resizeSearch = true;
    }

    openDialog(option: any) {
        this.modalPopupService.setPopup(option);
        setTimeout(() => {
            this.dialogRef = this.dialog.open(GlobalmodalpopupComponent, {
                panelClass: 'global-modal-popup',
            });
            this.dialogRef.disableClose = true;
        }, 200);
        this.closeMmenu();
    }

    getStorage() {
        this.userId = localStorage.getItem('userId');
        this.userData.name = localStorage.getItem('userName');
        this.userData.email = localStorage.getItem('email');
        this.userData.profileImage = localStorage.getItem('profile_pic');
        if (this.userData.profileImage && !this.userData.profileImage.includes('googleusercontent.com')) {
            this.userData.profileImage = 'https://api.tqllc.company/' + this.userData.profileImage;
        }
        this.userData.access_token = localStorage.getItem('access_token');
    }

    getUserDetails(): void {
        this.userService.getUserDetails(this.userId).subscribe((Response: any) => {
            if (Response.meta.code == 200) {
                // this.getUserHistory();
            }
            else {
                this.openToaster(false, true, 'Unauthorized Access.');
                this.router.navigate(['/unauthorized']);
            }
        }, (error: any) => {
            if (error?.error?.error?.code == 1021) {
                this.openToaster(false, true, 'Your Free Trial is completed. Please purchase a plan to continue.');
                this.router.navigate(['/expired']);
            } else if (error?.error?.error?.code == 1022) {
                this.openToaster(false, true, 'Your Subscription is completed. Please purchase a plan to continue.');
                this.router.navigate(['/plan-expired']);
            } else {
                this.openToaster(false, true, 'Unauthorized Access.');
                this.router.navigate(['/unauthorized']);
            }
            console.log(error);
        });
    }

    logout() {
        this.userService.logout();
    }

    openToaster(isSuccess: boolean, isError: boolean, message: string) {
        if (isSuccess) {
            this.showSuccessToaster = true;
            this.showSuccessMsg = message;
        } else if (isError) {
            this.showErrorToaster = true;
            this.showErrorMsg = message;
        }

        setTimeout(() => {
            this.closeToaster();
        }, 10000);
    }

    getNextData() {
        this.isCachedStored = this.isCachedStored === 'true' ? 'false' : 'true';
        if (this.isCachedStored == 'true') {
            this.openToaster(true, false, 'Caching is enabled.');
        } else {
            this.openToaster(true, false, 'Caching is disabled.');
        }
        sessionStorage.removeItem('query_response');
        this.createAnalysis()
        localStorage.setItem('isCached', this.isCachedStored);
    }

    enableDemoData() {
        this.isDemoCheck = this.isDemoCheck === 'true' ? 'false' : 'true';
        if (this.isDemoCheck == 'true') {
            this.userService.setData({ enableRanking: true, enableAuthor: true }, true);
        } else {
            this.userService.setData({ enableRanking: false, enableAuthor: false }, false);
        }
    }

    openRootPage() {
        this.router.navigate(['/']);
    }

    closeToaster() {
        this.showErrorToaster = false;
        this.showErrorMsg = "";
        this.showSuccessToaster = false;
        this.showSuccessMsg = "";
    }
}
