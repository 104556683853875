import { Component, OnInit } from '@angular/core';
import { Router, Event, NavigationStart, NavigationEnd } from '@angular/router';

declare var $: any;;

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
	title = 'tqllcdashboard';

	currentRoute: string;
	checkAssertion: boolean = false;

	constructor(private router: Router) {
		this.currentRoute = "";
		this.router.events.subscribe((event: Event) => {
			if (event instanceof NavigationEnd) {
				if (event.url.split('?')[0])
					this.currentRoute = event.url.split('?')[0];
				else
					this.currentRoute = event.url;
			}
		});
	}

	ngOnInit() {
	}
}
