import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { ModalPopupService } from '../services/modalPopup.services';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { GlobalmodalpopupComponent } from '../globalmodalpopup/globalmodalpopup.component';
import { SharedService } from './../shared.service';
import { Subscription } from 'rxjs';
import { Router, ActivatedRoute, Event, NavigationEnd } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import { UserService } from '../services/user.service';
import { GlobalConfirmationPopupComponent } from '../global-confirmation-popup/global-confirmation-popup.component';

@Component({
	selector: 'app-search-results',
	templateUrl: './search-results.component.html',
	styleUrls: ['./search-results.component.scss']
})
export class SearchResultsComponent implements OnInit {
	// Loader & Error Handling Variables
	isLoading: boolean = false;
	showSuccessToaster: boolean = false;
	showSuccessMsg: string = "";
	showErrorToaster: boolean = false;
	showErrorMsg: string = "";

	clickEventSubscription: Subscription;
	downloadOptions: boolean = false;
	downloadPew: boolean = false;
	showMCategory: boolean = false;
	active_tab: string;
	rightPanelAction: boolean = false;
	dialogRef: MatDialogRef<any>;
	searchResponse: any = {};
	searchQuery: string = '';

	constructor(private modalPopupService: ModalPopupService, private route: ActivatedRoute, public userService: UserService, private router: Router, private http: HttpClient, private sharedservice: SharedService, private dialog: MatDialog) {
		this.clickEventSubscription = this.sharedservice.getClickEvent().subscribe(() => {
			this.gridChange();
		});

		this.userService.appLoading$.subscribe(appLoad => {
			if (appLoad) {
				this.isLoading = true;
			} else {
				this.isLoading = false;
			}
		});
	}

	openDialog(option: any) {
		this.modalPopupService.setPopup(option);
		setTimeout(() => {
			this.dialogRef = this.dialog.open(GlobalmodalpopupComponent, {
				panelClass: ['global-modal-popup', 'animate__animated', 'zoomIn']
			});

			this.dialogRef.disableClose = true;

		}, 200);
	}

	ngOnInit(): void {
		this.route.queryParams
			.subscribe(params => {
				this.searchQuery = params.search;
				let queryResponse = JSON.parse(sessionStorage.getItem('query_response'));

				if (queryResponse && queryResponse?.title == params.search && queryResponse?.response?.resultCode == 1) {
					this.searchResponse = queryResponse?.response;
				} else {
					// this.isLoading = true;
					this.userService.setLoaderEvent(true);
					let userId = localStorage.getItem('userId');
					let token = localStorage.getItem('access_token');
					this.userService.searchAnalysis(this.searchQuery, token, userId).subscribe((Response: any) => {
						// this.isLoading = false;
						this.userService.setLoaderEvent(false);
						if (Response.query && Response.resultCode == 1) {
							this.searchResponse = Response;
						} else if (Response.resultCode == 0) {
							this.userService.setLoaderEvent(false);
							setTimeout(() => {
								this.dialog.open(GlobalConfirmationPopupComponent, { disableClose: true });
								this.router.navigate(['/'], { queryParams: { id: userId } });
							}, 200);
						} else {
							this.openToaster(false, true, 'Failed to retrieve search results for "' + this.searchQuery + '". Server is currently busy, please try again in some time.');
						}
					}, (error: any) => {
						this.isLoading = false;
						this.userService.sendErrorEmailReport(token);
						this.openToaster(false, true, 'Failed to retrieve search results for "' + this.searchQuery + '". Server is currently busy, please try again in some time.');
					});
				}
			});

		this.userService.querySub$.subscribe(queryLoad => {
			this.isLoading = false;
			if (queryLoad) {
				let queryResponse = JSON.parse(sessionStorage.getItem('query_response'));

				if (queryResponse && queryResponse?.response?.resultCode == 1) {
					this.searchResponse = queryResponse?.response;
				}
			}
		});
	}

	downloadDOption() {
		this.downloadOptions = !this.downloadOptions;
	}

	downloadMOption() {
		this.downloadPew = !this.downloadPew;
	}

	viewMTabs() {
		this.showMCategory = !this.showMCategory;
	}

	closeMDownload() {
		this.downloadPew = false;
		this.showMCategory = false;
	}

	closeDDownload() {
		this.downloadOptions = false;
	}

	gridChange() {
		this.rightPanelAction = !this.rightPanelAction;
	}

	downloadAsPDF() {
		let leftsideElement = document.getElementsByClassName('left-menu-section')[0];
		let divElement: any = document.getElementsByClassName('main_dashboard_section')[0];
		divElement.removeChild(leftsideElement);

		let mainDashboardPosition = document.getElementsByClassName("main_dashboard_section")[0]['offsetLeft'];

		let HTML_Width = divElement.offsetWidth;
		let HTML_Height = document.getElementsByClassName("main_dashboard_section")[0]['offsetHeight'];
		divElement.scrollTop = HTML_Height;
		divElement.style.height = HTML_Height + "px";
		let top_left_margin = 15;
		let PDF_Width = HTML_Width + (top_left_margin * 2);
		let PDF_Height: any = (PDF_Width * 1);

		html2canvas(divElement).then(function (canvas) {
			document.getElementsByClassName("main_dashboard_section")[0]['style']['left'] = mainDashboardPosition + "px";
			divElement.append(leftsideElement);
			let userName = localStorage.getItem('userName');
			let doc = new jsPDF();
			let page_height = doc.internal.pageSize.height;
			let imgData = canvas.toDataURL("image/jpeg", 1.0);
			let pdf = new jsPDF({
				"orientation": 'p',
				"unit": 'px',
				"format": [PDF_Width, PDF_Height],
				compress: true
			});
			pdf.addImage(imgData, 'JPG', top_left_margin, top_left_margin, HTML_Width, HTML_Height);
			pdf.save(userName + " search results.pdf");
		}).catch(function (error) {
			console.log("error while generating CANVAS image")
			console.log(error, error.stack);
		});

		// this.openToaster(false, true, 'Download PDF feature is coming soon...!');
	}

	openToaster(isSuccess: boolean, isError: boolean, message: string) {
		if (isSuccess) {
			this.showSuccessToaster = true;
			this.showSuccessMsg = message;
		} else if (isError) {
			this.showErrorToaster = true;
			this.showErrorMsg = message;
		}

		setTimeout(() => {
			this.closeToaster();
		}, 10000);
	}

	closeToaster() {
		this.showErrorToaster = false;
		this.showErrorMsg = "";
		this.showSuccessToaster = false;
		this.showSuccessMsg = "";
	}
}