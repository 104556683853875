<!-- page smart loaders -->
<div class="search-page-loader" *ngIf="isLoading">
    <div class="answer-loader">
        <div class="left-ans-loader">
            <div class="loader-container">
                <div class="animated-background first"></div>
                <div class="animated-background second"></div>
                <div class="animated-background link"></div>
            </div>
        </div>
        <div class="right-ans-loader">
            <div class="loader-container">
                <div class="animated-background mtitle"></div>
                <div class="animated-background meter"></div>
                <div class="animated-background mvalue"></div>
            </div>
        </div>
    </div>

    <div class="news_anns">
        <div class="news__title">
            <div class="loader-container">
                <div class="animated-background"></div>
            </div>
        </div>
        <div class="main-w-loader">
            <div class="m-wl-loader">
                <div class="loader-container">
                    <div class="nw__lft">
                        <div class="img clearfix">
                            <div class="animated-background im"></div>
                            <div class="im-t">
                                <div class="animated-background"></div>
                                <div class="animated-background"></div>
                            </div>
                        </div>
                        <div class="lft__desp">
                            <div class="animated-background"></div>
                            <div class="animated-background"></div>
                        </div>
                        <div class="lft__count">
                            <div class="animated-background"></div>
                        </div>
                    </div>
                    <div class="nw__rht">
                        <div class="animated-background"></div>
                    </div>
                </div>
            </div>
            <div class="m-wl-loader">
                <div class="loader-container">
                    <div class="nw__lft">
                        <div class="img clearfix">
                            <div class="animated-background im"></div>
                            <div class="im-t">
                                <div class="animated-background"></div>
                                <div class="animated-background"></div>
                            </div>
                        </div>
                        <div class="lft__desp">
                            <div class="animated-background"></div>
                            <div class="animated-background"></div>
                        </div>
                        <div class="lft__count">
                            <div class="animated-background"></div>
                        </div>
                    </div>
                    <div class="nw__rht">
                        <div class="animated-background"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="news_anns cc-loader">
        <div class="news__title">
            <div class="loader-container">
                <div class="animated-background"></div>
            </div>
        </div>
        <div class="main-w-loader">
            <div class="m-wl-loader">
                <div class="loader-container">
                    <div class="nw__lft">
                        <div class="img clearfix">
                            <div class="animated-background im"></div>
                            <div class="im-t">
                                <div class="animated-background"></div>
                                <div class="animated-background"></div>
                            </div>
                        </div>
                        <div class="lft__desp">
                            <div class="animated-background"></div>
                            <div class="animated-background"></div>
                        </div>
                        <div class="lft__count">
                            <div class="animated-background"></div>
                        </div>
                    </div>
                    <div class="nw__rht">
                        <div class="animated-background"></div>
                    </div>
                </div>
            </div>
            <div class="m-wl-loader">
                <div class="loader-container">
                    <div class="nw__lft">
                        <div class="img clearfix">
                            <div class="animated-background im"></div>
                            <div class="im-t">
                                <div class="animated-background"></div>
                                <div class="animated-background"></div>
                            </div>
                        </div>
                        <div class="lft__desp">
                            <div class="animated-background"></div>
                            <div class="animated-background"></div>
                        </div>
                        <div class="lft__count">
                            <div class="animated-background"></div>
                        </div>
                    </div>
                    <div class="nw__rht">
                        <div class="animated-background"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- warning toast starts -->
<div class="toast error static-toaster" *ngIf="showErrorToaster">
    <div class="outer-container">
        <img src="../../assets/images/svg/warning-icon.svg" alt="warning toaster">
    </div>
    <div class="inner-container">
        <h3>Error</h3>
        <p>{{showErrorMsg}}</p>
    </div>
    <a  (click)="closeToaster()"><img src="../../assets/images/svg/Cancel.svg"
            alt="close alert" /></a>
</div>
<div class="toast success static-toaster" *ngIf="showSuccessToaster">
    <div class="outer-container">
        <img src="../../assets/images/svg/success-toaster-icon.svg" alt="warning toaster">
    </div>
    <div class="inner-container">
        <h3>Success</h3>
        <p>{{showSuccessMsg}}</p>
    </div>
    <a (click)="closeToaster()"><img src="../../assets/images/svg/Cancel.svg"
            alt="close alert" /></a>
</div>
<!-- warning toast ends -->

<div class="welcome-message-section" *ngIf="!isLoading">
    <!-- answer and truth meter section -->
    <div class="top-truth-ans-section">
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-9">
                    <div class="answer-section clearfix">
                        <a (click)="openDialog('report_bug')" class="report-bug">Click to report a
                            bug</a>
                        <div class="copy-content">
                            <a  title="Quick Text Copy" (click)="copyAnswerText()"><img
                                    src="../../assets/images/svg/copied-icon.svg" alt="copied icon" /></a>
                        </div>
                        <h3>Answer <span class="label_text" *ngIf="labelText && !enableAuthor && env === 'dev-portal'">{{labelText}}</span></h3>
                        <!-- <div class="answer-list" [ngClass]="moreCont ? 'showM' : 'showL'"> -->
                        <div class="answer-list showM">
                            <div class="answer-sec" [innerHTML]="answerBox" id="answerBox_id">
                            </div>
                        </div>
                        <span class="general_text">{{general_text}}</span>
                        <ul>
                            <li>
                                <!-- <a  class="more-text" *ngIf="answerBox.length > 250"
                                    (click)="loadMore()">{{ moreCont ? 'Read Less': 'Read More' }}</a> -->
                                <!-- <a  class="more-text"
                                    >Read more</a> -->
                                <!-- <p class="general_text">{{general_text}}</p> -->

                            </li>
                            <li>
                                <!-- <p>{{general_text}}</p> -->
                                <!-- <a  (click)="openDialog('report_bug')">Click to report a
                                    bug</a> -->
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-3">
                    <app-truth-meter-chart [truthMeter]="pvgprogress" [labelVal]="searchResp.probabilitySource"
                        [ranking]="enableRanking"></app-truth-meter-chart>
                </div>
            </div>
        </div>
    </div>

    <!-- News & Announencement section -->
    <div class="news-annouence-section" *ngIf="newsAgencies.length > 0">
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-12">
                    <div class="section-title">
                        <h3>News / Announcement</h3>
                    </div>
                    <div class="na-section" [ngClass]="{'single-news' : newsAgencies.length == 1, 'news-fixed' : newsAgencies.length == 2}">
                        <div class="slick-slider news-container">
                            <div class="news-block" *ngFor="let newsAgency of newsAgencies; let i = index">
                                <div class="news-dtl_sec">
                                    <div class="author clearfix">
                                        <div class="author-image">
                                            <img src="{{newsAgency.iconUrl}}" class="image-logo"
                                                style="cursor: pointer; width: 100%;"
                                                (click)="viewMore(newsAgency.urlSearchRes)"
                                                *ngIf="newsAgency.iconUrl" />
                                        </div>
                                        <div class="author-details">
                                            <h3 *ngIf="newsAgency?.title">{{newsAgency?.name.length > 20 ?
                                                newsAgency?.name.slice(0, 20)+ '...': newsAgency?.name}}
                                            </h3>
                                            <p class="urllink" (click)="viewMore(newsAgency?.urlSearchRes)"
                                                *ngIf="newsAgency?.urlSearchRes" title="{{newsAgency?.urlSearchRes}}">
                                                {{newsAgency?.urlSearchRes.length > 80 ?
                                                newsAgency?.urlSearchRes.slice(0, 80)+ '...': newsAgency?.urlSearchRes}}
                                            </p>
                                        </div>
                                    </div>
                                    <div class="news-title">
                                        <h4 (click)="viewMore(newsAgency?.urlSearchRes)" class="news-title-head">
                                            {{newsAgency?.title || 'No Title Available'}}</h4>
                                    </div>
                                    <div class="news-score clearfix">
                                        <div class="score red"
                                            *ngIf="newsAgency.newsAgency.adfontesmediaRank.reliability >= 0 && newsAgency.newsAgency.adfontesmediaRank.reliability <= 33">
                                            {{newsAgency.newsAgency.adfontesmediaRank.reliability
                                            | number: '1.0-0'}}</div>
                                        <div class="score yellow"
                                            *ngIf="newsAgency.newsAgency.adfontesmediaRank.reliability >= 34 && newsAgency.newsAgency.adfontesmediaRank.reliability <= 66">
                                            {{newsAgency.newsAgency.adfontesmediaRank.reliability
                                            | number: '1.0-0'}}</div>
                                        <div class="score green"
                                            *ngIf="newsAgency.newsAgency.adfontesmediaRank.reliability >= 67 && newsAgency.newsAgency.adfontesmediaRank.reliability <= 100">
                                            {{newsAgency.newsAgency.adfontesmediaRank.reliability
                                            | number: '1.0-0'}}</div>
                                        <div class="news-fall-meter">
                                            <div [id]="'linear-guage-div'+i"></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="news-img_sec">
                                    <img [src]="(newsAgency?.cse_thumb) ? newsAgency?.cse_thumb : '../../../assets/images/default-image.jpg'"
                                        alt="news image" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- create creation -->
    <div class="create-creation-sec">
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-12">
                    <div class="inn-cc-section clearfix">
                        <div class="cc-create-left">
                            <h3>Create citation</h3>
                            <p>Please select the cards from the below list to generate citations</p>
                        </div>
                        <div class="cc-action-right">
                            <ul>
                                <li>
                                    <div class="select_creation">
                                        <mat-form-field appearance="fill">
                                            <mat-label>Citation Format</mat-label>
                                            <mat-select (selectionChange)="onWriterChange($event)">
                                                <mat-option *ngFor="let citation of citations" [value]="citation.value">
                                                    {{citation.viewValue}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                </li>
                                <li>
                                    <button class="create-citation-btn" (click)="createCitation()">Create
                                        Citation</button>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <!-- Supporting URLs -->
                    <div class="cc-sub-section" *ngIf="supportingUrls?.results.length > 0">
                        <div class="cc-sub-title">
                            <h3>Supporting URLs</h3>
                        </div>
                        <div class="slick-slider">
                            <div class="main-spt-section" *ngFor="let result of supportingUrls.results">
                                <div class="cc-left-sec clearfix">
                                    <div class="cc-check clearfix">
                                        <mat-checkbox [value]="result.id"
                                            (change)="onCheckboxChange($event, result)"></mat-checkbox>
                                        <div class="cc-check-title">
                                            <h4 class="linkToOpen" (click)="viewMore(result.url)">{{result.title}}
                                                <div class="ext-tooltip">
                                                    <!-- <span class="link-icon"></span> -->
                                                    <div class="ext-link-tooltip">
                                                        {{result.url}}
                                                    </div>
                                                </div>
                                            </h4>
                                        </div>
                                    </div>
                                    <p>{{result.snipet}}</p>
                                    <!-- <div class="score-block">
                                        Score: {{result.rank}}
                                    </div> -->
                                </div>
                                <div class="cc-right-sec">
                                    <img [src]="result.cse_thumb ? result.cse_thumb : '../../../assets/images/default-image.jpg'"
                                        alt="supporting img" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Additional Context -->
                    <div class="cc-sub-section additional-context" *ngIf="debunkingUrls?.results.length > 0">
                        <div class="cc-sub-title">
                            <h3>Additional Context</h3>
                        </div>
                        <div class="slick-slider">
                            <div class="main-spt-section" *ngFor="let result of debunkingUrls.results">
                                <div class="cc-left-sec clearfix">
                                    <div class="cc-check clearfix">
                                        <mat-checkbox [value]="result.id"
                                            (change)="onCheckboxChange($event, result)"></mat-checkbox>
                                        <div class="cc-check-title">
                                            <h4 class="linkToOpen" (click)="viewMore(result.url)">{{result.title}}
                                                <div class="ext-tooltip">
                                                    <!-- <span class="link-icon"></span> -->
                                                    <div class="ext-link-tooltip">
                                                        {{result.url}}
                                                    </div>
                                                </div>
                                            </h4>
                                        </div>
                                    </div>
                                    <p>{{result.snipet}}</p>
                                    <!-- <div class="score-block">
                                        Score: {{result.rank}}
                                    </div> -->
                                </div>
                                <div class="cc-right-sec">
                                    <img [src]="result.cse_thumb ? result.cse_thumb : '../../../assets/images/default-image.jpg'"
                                        alt="Additional img" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Fact checker URLs -->
                    <div class="cc-sub-section additional-context" *ngIf="factCheckUrls?.results.length > 0">
                        <div class="cc-sub-title">
                            <h3>Fact checker URLs</h3>
                        </div>
                        <div class="slick-slider">
                            <div class="main-spt-section" *ngFor="let result of factCheckUrls.results">
                                <div class="cc-left-sec clearfix">
                                    <div class="cc-check clearfix">
                                        <mat-checkbox [value]="result.id"
                                            (change)="onCheckboxChange($event, result)"></mat-checkbox>
                                        <div class="cc-check-title">
                                            <h4 class="linkToOpen" (click)="viewMore(result.url)">{{result.title}}
                                                <div class="ext-tooltip">
                                                    <!-- <span class="link-icon"></span> -->
                                                    <div class="ext-link-tooltip">
                                                        {{result.url}}
                                                    </div>
                                                </div>
                                            </h4>
                                        </div>
                                    </div>
                                    <p *ngIf="result.snipet">{{result.snipet}}</p>
                                    <!-- <div class="score-block">
                                        Score: {{result.rank}}
                                    </div> -->
                                </div>
                                <div class="cc-right-sec">
                                    <img [src]="result.cse_thumb ? result.cse_thumb : '../../../assets/images/default-image.jpg'"
                                        alt="Additional img" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Results from Wikipedia -->
                    <div class="cc-sub-section additional-context" *ngIf="wikiUrls?.results.length > 0">
                        <div class="cc-sub-title">
                            <h3>Results from Wikipedia</h3>
                        </div>
                        <div class="slick-slider">
                            <div class="main-spt-section" *ngFor="let result of wikiUrls.results">
                                <div class="cc-left-sec clearfix">
                                    <div class="cc-check clearfix">
                                        <mat-checkbox [value]="result.id"
                                            (change)="onCheckboxChange($event, result)"></mat-checkbox>
                                        <div class="cc-check-title">
                                            <h4 class="linkToOpen" (click)="viewMore(result.url)">{{result.title}}
                                                <div class="ext-tooltip">
                                                    <!-- <span class="link-icon"></span> -->
                                                    <div class="ext-link-tooltip">
                                                        {{result.url}}
                                                    </div>
                                                </div>
                                            </h4>
                                        </div>
                                    </div>
                                    <p *ngIf="result.snipet">{{result.snipet}}</p>
                                    <!-- <div class="score-block">
                                        Score: {{result.rank}}
                                    </div> -->
                                </div>
                                <div class="cc-right-sec">
                                    <img [src]="result.cse_thumb ? result.cse_thumb : '../../../assets/images/default-image.jpg'"
                                        alt="Additional img" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="page-footer text-center">
    <!-- <a  class="scroll-to-top">Scroll to Top</a> -->
    <div class="inn-footer-logo"><a ><img src="../../assets/images/tqllc-footer-logo.png"
                alt="welcome footer logo" /></a></div>
</div>